import socket from '../services/socket';

const promisifiedSocket = (event, confirmCallback) => new Promise((resolve) => {
  socket.on(event, (params) => {
    if (confirmCallback) {
      if (confirmCallback(params)) {
        resolve(params);
      }
    } else {
      resolve(params);
    }
  });
});

export default promisifiedSocket;
