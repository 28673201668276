import styled, { keyframes } from 'styled-components';
import { Modal, Input, Checkbox } from 'antd';

import Colors from '../../styles/Colors';

const timer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const Timer = styled.div`
  position: absolute;
  display: list-item;
  background-image: ${Colors.gradiendBackground};
  width: 100%;
  height: .8rem;
  border-radius: 0 2rem 2rem 0;
  animation: ${timer} ${({ duration }) => duration}s linear 0s 1 reverse forwards;
`;

const Content = styled.section`
  background-color: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 8.4rem;
  flex-direction: column;
  padding-top: 25vh;

  @media (max-width: 1024px) {
    padding: 0;
    margin: 0;
  }

  @media (min-width: 1080px) {
    padding-top: 0vh;
    height: 30vh;
  }

  @media (min-width: 1920px) {
    padding-top: 0vh;
    height: 50vh;
  }
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 3.15rem;
  color: ${Colors.black};
  letter-spacing: 0.13rem;
  margin-top: 10rem;
  margin-bottom: 10rem;

  @media (max-width: 1024px) {
    margin-top: 26vh;
    font-size: 1.3rem;
    margin-left: 0;
    margin-right: 0;
    width: 90%;
  }

  @media (min-width: 1920px) {
    margin-bottom: 9rem;
  }
`;

const Icon = styled.img`
  width: 33rem;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1024px) {
    width: 50%;
    margin: 0;
    position: absolute;
    top: 45vh;
    z-index: -1;
  }

  @media (min-width: 1920px) {
    width: 20rem;
  }
`;

const Button = styled.button`
  font-size: 3rem;
  font-weight: 500;
  color: ${Colors.black};
  position: fixed;
  bottom: 0;
  left: 0;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: #fff;
  background-image: linear-gradient(to right, #00efd1, #00acea);
  padding: 40px 0;
  z-index: 10;

  cursor: pointer;

  @media (max-width: 1024px) {
    margin: 0;
    font-size: 1rem;
    padding: 15px 0;
  }
`;

Button.Text = styled.p`
  font-size: 1.9rem;
  letter-spacing: .08rem;
  font-weight: 600;
  color: ${Colors.black};
  margin: 0;
  margin-left: 1rem;

  @media (max-width: 1024px) {
    margin: 0;
    font-size: 1.1rem;
    margin-left: 10px;
  } 
`;

const LoadingOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: ${Colors.black};
  opacity: 0.5;
`;

const EmailModal = styled(Modal)`
  width: 90% !important;
  margin: auto;

  .ant-modal-content {
    border-radius: 15px !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .ant-btn-background-ghost {
    background: transparent !important;
    text-shadow: none !important;
    border: none !important;
    color: transparent !important;
  }
  
  .ant-modal-footer {
    padding: 0 !important;
  }

  @media (min-width: 1024px) {
    width: 80vw !important;
    min-height: 400px !important;
  }
`;

const ModalTitle = styled.h3`
  text-align: center;
  width: 80%;
  font-weight: bold;
  margin: 0;
  margin-top: 30px;

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
`;

const CloseModal = styled.img`
    width: 40px;
    margin-top: 10px;
    margin-right: 10px;

  @media (max-width: 1024px) {
    width: 25px;
  }
`;

const ModalForm = styled.form`
  width: 90%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputModal = styled(Input)`
    width: 100%;
    border-radius: 5px;

  @media (min-width: 1024px) {
    border-radius: 15px;
    width: 90%;
    font-size: 2.5rem !important;
  }
`;

const NewsLetterCointainer = styled.div`
  width: 100%;
  margin-bottom: 70px;

  @media (min-width: 1024px) {
    margin-top: 30px;
    font-size: 2rem;
    margin-bottom: 100px;
    width: 90%;
  }
`;

const NewsLetterCheckbox = styled(Checkbox)`
  margin-right: 10px;
  margin-top: 20px;
  margin-right: 10px;

  @media (min-width: 1024px) {
    margin-right: 30px;

    .ant-checkbox-inner {
      width: 30px;
      height: 30px;
    }
  }
`;

const OkButton = styled.button`
  background-image: linear-gradient(to top, #00acea, #00efd1);
  border-radius: 15px 0 15px 0;
  font-size: 1.2rem;
  border: none;
  color: #fff;
  padding: 10px 30px;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;

export {
  Content,
  Text,
  Icon,
  Button,
  LoadingOverlay,
  Timer,
  EmailModal,
  CloseModal,
  ModalTitle,
  InputModal,
  ModalForm,
  NewsLetterCointainer,
  NewsLetterCheckbox,
  OkButton,
};
