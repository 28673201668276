import React, {
  useState,
  useEffect,
} from 'react';

import { Redirect, useLocation } from 'react-router';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';

import { Button, Input, Switch } from 'antd';
import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';
import logotipovend from './Assets/logo-vend-market.svg';
import people04 from './Assets/people04.png';
import background from './Assets/bg-vend-market.jpg';

function ValidacaoCadastro() {
  const [redirect, setRedirect] = useState(null);
  const [document_id, setDocumentId] = useState('');
  const backNavigation = () => setRedirect('../');
  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const handleValidarConta = async () => {
    if (document_id === '') {
      toast.error('Informe seu CPF para avançar.');
      return false;
    }

    try {
      const res = await api.post('/customers/validate', { document_id });
      const { customer } = res.data.data;
      toast.success('CPF validado com sucesso.');
      setRedirect({ pathname: '/ativacao-cadastro', state: customer });
    } catch (error) {
      console.error(error);
      toast.error('O CPF informado não foi encontrado.');
    }
  };

  const [size, setSize] = useState('large');

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people04})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Validação Conta</h2>
            <h3>Que bom ter você de volta!</h3>
            <h4>Por favor, insira o número do seu CPF para validar sua conta conosco.</h4>
            <InputMask
              mask="999.999.999-99"
              maskChar=""
              size="large"
              name="document_id"
              placeholder="CPF"
              value={document_id}
              onChange={(e) => setDocumentId(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
            <Button type="primary" size={size} block className="azul" onClick={() => handleValidarConta()}>
              Confirmar
            </Button>
            <Button type="primary" size={size} block className="azul" onClick={() => goTo('cadastro')}>
              Criar minha conta
            </Button>

          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default ValidacaoCadastro;
