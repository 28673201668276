import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { Redirect, useLocation } from 'react-router';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  Container,
  OptionsContainer,
  OptionList,
  UniqueOptionList,
  FooterCancelButton,
} from './styles';
import {
  Header, TopAd,
} from '../../components';

import { CartContext } from '../../state/CartContext';

import creditIcon from '../../assets/credit.png';
import debitIcon from '../../assets/debit.png';
import voucherIcon from '../../assets/voucher.png';
import pixIcon from '../../assets/pix.png';
import cardIcon from '../../assets/card-icon.png';
import { useUserContext } from '../../state/UserContext';
import PaymentOption from './components/PaymentOption';

function Checkout() {
  const [redirect, setRedirect] = useState(null);
  const [userInfo] = useUserContext();
  const location = useLocation();
  const backNavigation = () => setRedirect('../carrinho');
  const pickPayment = (type) => setRedirect({ pathname: '../payment', state: { paymentType: type, buyerMobile: location.state.celular } });
  const pickSampling = () => pickPayment('sampling');

  const { cartDispatch } = useContext(CartContext);

  const cleanCart = () => cartDispatch({ type: 'CLEAN_CART' });

  function cancelSale() {
    cleanCart();
    setRedirect('../');
  }

  function OptionButton(src, description, onClick, key) {
    return (
      <PaymentOption 
        src={src} 
        description={description}
        onClick={onClick}
        key={key}
      />
    )
  }

  const paymentOptions = [
    {
      available: userInfo?.machine?.hasCredit,
      component: () => OptionButton(creditIcon, 'Crédito', () => pickPayment('credit'), 'credit'),
    },
    {
      available: userInfo?.machine?.hasDebit,
      component: () => OptionButton(debitIcon, 'Débito', () => pickPayment('debit'), 'debit'),
    },
    {
      available: userInfo?.machine?.hasVoucher,
      component: () => OptionButton(voucherIcon, 'Voucher', () => pickPayment('voucher'), 'voucher'),
    },
    {
      available: userInfo?.machine?.hasPix,
      component: () => OptionButton(pixIcon, 'PIX', () => pickPayment('pix'), 'pix'),
    }, 
    {
      available: userInfo?.machine?.hasCard,
      component: () => OptionButton(cardIcon, 'Cartão Cadastrado', () => pickPayment('card'), 'card')
    },
  ];
  
  useEffect(() => {
    if (userInfo?.machine?.sampling === true) {
      pickSampling();
    }
  }, []);

  if (redirect) return <Redirect to={redirect} />;
  return (
    <>
      {/* <TopAd hasStatusBar={false} /> */}
      <LowerAdSection>
        <Container>
          <OptionsContainer>
            <Header title="Forma de Pagamento" backNavigation={backNavigation} />
            {paymentOptions.filter(elem => elem.available === true).length === 1 ? (
            <UniqueOptionList>
              {paymentOptions.find(elem => elem.available === true).component()}
            </UniqueOptionList>) : (
            <OptionList>
              {paymentOptions.filter(elem => elem.available === true).map(elem => elem.component())}
            </OptionList> )}
          </OptionsContainer>
        <FooterCancelButton onClick={cancelSale}>
          Cancelar Compra
        </FooterCancelButton>
        </Container>
      </LowerAdSection>
    </>
  );
}

export default Checkout;
