import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { Redirect } from 'react-router';
import { Button, Input, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
  Link,
  CardModal,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';
import logotipovend from './Assets/logo-vend-market.svg';
import people02 from './Assets/people02.png';
import background from './Assets/bg-vend-market.jpg';
import UserContext from '../../state/UserContext';
import AddressModal from './components/AddressModal';

function CustomerMyData() {
  const [redirect, setRedirect] = useState(null);
  const [full_name, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const [document_id, setDocumentId] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');
  const [size, setSize] = useState('large');
  const [currentAddress, setCurrentAdress] = useState({});
  const [address, setAddress] = useState({});
  const [cep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [modalAdressShow, setModalAdressShow] = useState(false);

  const [userInfo, setUserInfo] = useContext(UserContext);

  const addressSubmit = async () => {
    if(cep.length === 8 && numero.length > 0 && address?.localidade){
      try {
        const response = await api.patch(`/customers/${userInfo?.customer._id}`, {
          address: {
            public_place: address.logradouro,
            neighborhood: address.bairro,
            complement: complemento ? complemento : '',
            number: numero,
            cep: cep,
            city: address.localidade,
            state: address.uf,
        }
        });
        setUserInfo({
          ...userInfo,
          customer: {
            ...userInfo.customer,
            address: response.data.data.address,
          }
        });
  
        toast.success("Endereço atualizado com sucesso!");
      } catch (error) {
        toast.error(error);
      }
    } else if (cep.length < 8){
      throw new Error("CEP inválido");
    } else if (numero.length === 0){
      throw new Error("Número é obrigatório");
    } else {
      throw new Error("Ocorreu um erro, tente novamente");
    }
  }

  useEffect(() =>{
   const getCep = async () => {
      const response = await axiosGet(`/get-cep/?cep=${cep}`);
      setAddress(response.data);
   } 
   if (cep.length === 8 && cep !== currentAddress.cep) {
     getCep();
   }
  }, [cep]);

  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const formatDate = (dateString, destinationFormat) => {
    dateString = dateString.substr(0,10);

    // Function to pad single digit numbers with leading zero
    const pad = (num) => num < 10 ? '0' + parseInt(num) : num;

    // Split the input date string based on the format
    const parts = dateString.split(destinationFormat === 'en' ? '/' : '-');
    // Extract day, month, and year
    const day = pad(parts[destinationFormat === 'en' ? 0 : 2]);
    const month = pad(parts[1]);
    const year = parts[destinationFormat === 'en' ? 2 : 0];

    // Check the destination format
    if (destinationFormat === 'en') {
        return `${year}-${month}-${day}`;
    } else if (destinationFormat === 'pt-br') {
        return `${day}/${month}/${year}`;
    } else {
        // If destination format is not supported, return null
        return null;
    }
  }

  const handleSubmit = async () => {
    try {
      const mobileFormated = mobile.replace(/\D/g, "");

      if (full_name === '' || email === '' || document_id === '') {
        toast.error('Todos os campos devem ser preenchidos.');
        return false;
      }

      if (document_id.length < 14) {
        toast.error('Informe o CPF corretamente.');
        return false;
      }

      if (mobileFormated.length < 11) {
        toast.error('Informe o Celular corretamente.');
        return false;
      }

      if (birthday.length < 10) {
        toast.error('Informe a Data de Nascimento corretamente. DD/MM/YYYY');
        return false;
      }

      const result = await api.patch(`/customers/${userInfo?.customer._id}`, {
        name: full_name, 
        phone: mobile, 
        email, 
        document_id,
        birthday : formatDate(birthday, 'en'), 
      });

      setUserInfo({
        ...userInfo,
        customer: result.data.data,
      });

      toast.success('Dados atualizados com sucesso.');
    } catch (err) {
      if (err.response?.status === 400) { 
        toast.error('Já existe um cadastro com este email e telefone'); 
      } else { 
        toast.error('Ocorreu um erro, consulte a equipe de suporte'); 
      }
    }
  };

  useEffect(() => {
    setFullName(userInfo?.customer.name);
    setDocumentId(userInfo?.customer.document_id);
    setMobile(userInfo?.customer.phone);
    setEmail(userInfo?.customer.email);
    setBirthday(formatDate(userInfo?.customer.birthday, 'pt-br'));
    const customerAddress = userInfo?.customer.address;
    if (customerAddress) {
      setCurrentAdress(customerAddress);
      setCep(customerAddress.cep);
      setAddress({
        logradouro: customerAddress.public_place,
        localidade: customerAddress.city,
        uf: customerAddress.state,
        bairro: customerAddress.neighborhood,
      })
      setNumero(customerAddress.number);
      setComplemento(customerAddress.complement);
    }
  }, [userInfo]);

  const endereco = (`${currentAddress?.public_place}, ${currentAddress?.number} - ${currentAddress?.city}/${currentAddress?.state} `);
  
  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people02})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Meus Dados</h2>

            <h4>Você poderá atualizar seus dados aqui.</h4>

            <Input
              size="large"
              name="full_name"
              placeholder="Nome Completo"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
            />
            <InputMask
              mask="999.999.999-99"
              pattern="[0-9]*"
              maskChar=""
              size="large"
              name="document_id"
              id="document_id"
              placeholder="CPF"
              value={document_id}
              onChange={(e) => setDocumentId(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>

            <InputMask
              mask="99/99/9999"
              type='tel'
              pattern="[0-9]*"
              maskChar=""
              size="large"
              name="birthday"
              placeholder="Data de Nascimento"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>

            <InputMask
              mask="(99) 99999-9999"
              pattern="[0-9]*"
              type='tel'
              maskChar=""
              size="large"
              name="mobile"
              id="mobile"
              placeholder="Celular"
              value={mobile}
              readOnly="true"
              onChange={(e) => setMobile(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>
            <Input
              size="large"
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="primary" size={size} block className="azul" onClick={() => handleSubmit()}>
              Atualizar Dados
            </Button>
            <br />
            {userInfo?.customer.address?.cep && <>
              <Input
                disabled
                size="large"
                name="endereco"
                placeholder="endereco"
                value={endereco}
              />
            <CardModal>
            <EditOutlined style = {{color: '#fff'}}/>
              <Link onClick={() => setModalAdressShow(true)}>Atualizar Endereço</Link>
            </CardModal>
            <br />
            </>}
            <Button type="default" size={size} block className="" onClick={() => goTo('customer_menu')}>
              Voltar
            </Button>
          </Content>
        </CartSection>
      </LowerAdSection>
      <AddressModal 
        handleSubmit={addressSubmit}
        active={modalAdressShow}
        setActive={setModalAdressShow}
        cepState={{state: cep, setState: setCep}}
        addressState={{state: address, setState: setAddress}}
        numeroState={{state: numero, setState: setNumero}}
        complementoState={{state: complemento, setState: setComplemento}}
      />
    </>
  );
}

export default CustomerMyData;
