import React from 'react';
import { Spin } from 'antd';

import { Container } from './styles';

function LazyLoader({ page, small }) {
  return (
    <Container page={page} small={small}>
      <Spin size="large" spinning />
    </Container>
  );
}

export default LazyLoader;
