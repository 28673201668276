import styled from 'styled-components';
import Colors from '../../styles/Colors';

const CardContainer = styled.div`
  width: 33.33%;
  display: flex;

  @media (max-width: 1024px) {
    min-width: 150px;
  }

  @media (max-width: 900px) {
    width: 50%;
    min-width: 150px;
  }

  @media (min-width: 1920px) {
    width: 25%;
  }
`;

const Card = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .4rem;
  overflow: hidden;
`;

const TextContainer = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  color: ${Colors.black};
  width: 100%;
  font-size: 8vw;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: .07rem;
  font-size: 1.2rem;

  @media(min-width: 1024px) {
    font-size: 2rem;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 900px) {
    margin: 0;
  }
`;

const Price = styled.p`
  color: ${Colors.blue};
  font-size: 6vw;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: .084rem;
  font-size: 1.4rem;

  @media(min-width: 1024px) {
    font-size: 2.2rem;
  }
`;

const Image = styled.img`
  width: 100%;
  margin-bottom: 1.3rem;
`;

const OutOfStockOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-color: ${Colors.background};
  opacity: 0.4;
`;

export {
  Card,
  Title,
  Price,
  Image,
  CardContainer,
  OutOfStockOverlay,
  TextContainer,
};
