export default (promise, timeLimit, onTimeup) => new Promise((resolve, reject) => {
  let resolved = false;

  promise.then((params) => {
    if (!resolved) {
      resolve(params);
      resolved = true;
    }
  });

  setTimeout(() => {
    if (!resolved) {
      reject(onTimeup());
      resolved = true;
    }
  }, timeLimit);
});
