import React from "react";
import { OptionButtonWrapper, Button, Icon, OptionDescription } from "../styles";
export default function PaymentOption({onClick, src, description }){


    return (
        <OptionButtonWrapper>
            <Button onClick={onClick}>
                <Icon src={src} />
            </Button>
            <OptionDescription>{description}</OptionDescription>
        </OptionButtonWrapper>
    )
}