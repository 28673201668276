import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Message = styled.p`
  color: ${Colors.blue};
  margin: 0;
  font-size: 1.3rem;
  text-align: center;
  width: 80%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  height: 80%;

  transition: 500ms;
`;
