import styled from 'styled-components';
import { Button } from 'antd';
import Colors from '../../styles/Colors';

export const Container = styled.div`

  .example {
    .ant-drawer-content-wrapper {
      border-color: red;
      border-width: 2px;
      border-radius: 25px;
    }
  }
`;

export const ButtonLink = styled(Button)`
  margin: 0.1rem; 0;
  width: 15rem;
  border: none;
  box-shadow: 0 0 0 0;

  img {
    margin-right: 1.3rem;
    margin-left: 0;
    width: 1.5rem;
    max-height: 59px;
  }

  display: flex;
  align-items: center;

  transition: 500ms;

  &:hover {
    background-color: ${Colors.background};
    color: black;
  }
`;

export const ButtonLink2 = styled.button`
  /* margin: 5px 0; */

  border: none;
  box-shadow: 0 0 0 0;

  margin-left: -20px;

  img {
    width: 90px;
    height: 90px;
  }

  display: flex;
  align-items: center;

  background-color: rgba(0, 0, 0, 0);

  z-index: 2;
`;

export const OpenNav = styled.button.attrs({
  type: 'button',
})`
  position: fixed;
  z-index: 102;
  cursor: pointer;
  top: 1rem;
  left: 0.3rem;
  background: transparent;
  border: none;
  outline: none;

  img {
    width: 5.5rem;
  }
`;
