import React, { useState, useEffect, useRef } from 'react';

import {
  ErrorList,
  ErrorCard,
  Text,
  Icon,
  DetailsContainer,
  Title,
  MessageContainer,
} from './styles';

import { codes } from './errorMessages.json';
import arrowIcon from './assets/arrow.svg';

function ErrorDialog({ error }) {
  const {
    message,
    status,
    type,
    isSuccess,
    title,
  } = error;
  const [active, setActive] = useState(null);
  const [vanishingTimeout, setVanishingTimeout] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const messageRef = useRef(null);

  useEffect(() => {
    if (active === null) {
      setActive(true);
      setVanishingTimeout(setTimeout(() => {
        setActive(false);
      }, 3000));
    }
  }, [active]);

  const handleMouseEnter = () => {
    if (vanishingTimeout) clearTimeout(vanishingTimeout);
  };

  const handleMouseLeave = () => {
    setVanishingTimeout(setTimeout(() => {
      setActive(false);
    }, 3000));
  };

  return (
    <ErrorCard
      active={active}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isSuccess={isSuccess}
      type="button"
      // onClick={() => setShowDetails(!showDetails)}
    >
      <MessageContainer>
        <Title isSuccess={isSuccess}>{title || (status ? codes[status][type] : message)}</Title>
        {/* <Icon isSuccess={isSuccess} src={arrowIcon} upside={showDetails} /> */}
      </MessageContainer>
      <DetailsContainer
        active={showDetails}
        // This calculates the necessary height of the dropdown container when open
        // based on inner elements margins and paddings
        height={messageRef.current ? (
          parseFloat(messageRef.current?.offsetHeight)
          + 4.8 * 3
          + 1.6 * 3
          + 17 * 2
        ) : 0}
      >
        <Text>
          Mensagem:
          {' '}
          <span ref={messageRef}>{message}</span>
        </Text>
        <Text>
          Tipo:
          {' '}
          <span>{type}</span>
        </Text>
        <Text>
          Código:
          {' '}
          <span>{status || 'internal_error'}</span>
        </Text>
      </DetailsContainer>
    </ErrorCard>
  );
}

function ErrorHandler({ errorArray }) {
  return (
    <ErrorList>
      {errorArray.map((error) => <ErrorDialog key={error.id} error={error} />)}
    </ErrorList>
  );
}

export default ErrorHandler;
