import React from 'react';

import {
  LoadingContainer,
  LoadingIcon,
  LoadingText,
} from './styles';
import loadingSvg from '../../../assets/loading.svg';

function LoadingScreen({ text }) {
  return (
    <LoadingContainer>
      <LoadingText>{text[0]}</LoadingText>
      {text[1] && <LoadingText small>{text[1]}</LoadingText>}
      <LoadingIcon src={loadingSvg} />
    </LoadingContainer>
  );
}

export default LoadingScreen;
