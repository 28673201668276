import axios from 'axios';

import Cookies from 'js-cookie';

import url from '../url';

const api = axios.create({
  baseURL: url,
});

const axiosGet = async (
  relativeUrl = '/',
  setState = () => { },
  process = (data) => data,
  sucess = () => { },
  error = () => { },
) => {
  try {
    const match = document.cookie.match(/(^| )token=([^;]+)/);
    const token = match ? match[2] : null;

    const res = await api.get(relativeUrl, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      withCredentials: false,
    });

    Cookies.set('noConnection', 'false');
    if (setState) {
      setState(await process(res.data.data));
    } else {
      process(res.data.data);
    }
    sucess(res);
    return {
      code: '200',
      ...res,
    };
  } catch (err) {
    error(err);
    if (!err.response && err.isAxiosError) {
      Cookies.set('noConnection', 'true');
    }
    return { code: '404', response: err.response };
  }
};

const axiosPost = async (relativeUrl = '/', body = {}, success = () => { }, error = () => { }, shouldThrow = false) => {
  try {
    const match = document.cookie.match(/(^| )token=([^;]+)/);
    const token = match ? match[2] : null;

    // Make requisition
    const res = await api.post(relativeUrl, body, {
      timeout: 180000, // Set a timeout of 100 seconds
      headers: {
        authorization: `Bearer ${token}`,
      },
      withCredentials: false,
    });

    // Execute callback
    Cookies.set('noConnection', 'false');
    await success(res, body);
    return res;
  } catch (err) {
    if (!err.response && err.isAxiosError) {
      Cookies.set('noConnection', 'true');
    }
    await error(err);
    if (shouldThrow) {
      throw err;
    } else {
      return err;
    }
  }
};

const axiosPatch = async (relativeUrl = '/', body = {}, success = () => { }, error = () => { }) => {
  try {
    const match = document.cookie.match(/(^| )token=([^;]+)/);
    const token = match ? match[2] : null;

    // Make requisition
    const res = await api.patch(relativeUrl, body, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      withCredentials: false,
    });
    // Execute callback
    Cookies.set('noConnection', 'false');
    await success(res, body);
    return res;
  } catch (err) {
    if (!err.response && err.isAxiosError) {
      Cookies.set('noConnection', 'true');
    }
    await error(err);
    return err;
  }
};

const axiosDelete = async (relativeUrl = '/', data = {}, success = () => { }, error = () => { }) => {
  try {
    const match = document.cookie.match(/(^| )token=([^;]+)/);
    const token = match ? match[2] : null;

    // Make requisition
    const res = await api.delete(relativeUrl, {
      headers: {
        authorization: `Bearer ${token}`,
      },
      withCredentials: false,
      data,
    });
    // Execute callback
    Cookies.set('noConnection', 'false');
    await success(res);
    return res;
  } catch (err) {
    if (!err.response && err.isAxiosError) {
      Cookies.set('noConnection', 'true');
    }
    await error(err);
    return err;
  }
};

const checkToken = () => new Promise((resolve) => {
  const match = document.cookie.match(/(^| )token=([^;]+)/);
  if (!match) {
    resolve(false);
    return;
  }
  console.log({match});
  (async () => {
    try {
      const res = await api.get('/machines', {
        headers: {
          authorization: `Bearer ${match[2]}`,
        },
      });
      if (res.status === 200) resolve(true);
    } catch (err) {
      console.log(err.response);
      if (!err.response) return;
      const { status } = err.response;
      if (status === 401 || status === 400 || status === 500) resolve(false);
    }
  })();
});

export default api;

export {
  url,
  axiosGet,
  axiosPost,
  axiosPatch,
  axiosDelete,
  checkToken,
};
