import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useCookies } from 'react-cookie';

function ToggleHideCursor() {
  const [cookies, setCookie] = useCookies(['hideCursor']);
  const [hasSetCookie, setHasSetCookie] = useState(false);

  useEffect(() => {
    if (cookies.hideCursor === undefined) {
      setCookie('hideCursor', false, { path: '/' });
    } else {
      const isHide = cookies.hideCursor === 'false';
      setCookie('hideCursor', isHide, { path: '/' });
    }
    setHasSetCookie(true);
  }, []);

  if (hasSetCookie) return <Redirect to="/" />;

  return null;
}

export default ToggleHideCursor;
