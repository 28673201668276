import React, {
  useContext,
  useState,
  memo,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Container,
  Logo,
  Input,
  SubmitButton,
  Form,
  Message,
  BackgroundCorners,
  Content,
} from '../../components/Auth';

import api, { axiosGet, axiosPost } from '../../services/api';
import { useWindowSize } from '../../hooks/useWindowSize';
import UserContext from '../../state/UserContext';
import LoadingTouchless from './LoadingTouchless';

function Auth({ setLogged }) {
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [, setUserInfo] = useContext(UserContext);
  const [, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const createError = (text) => {
    setError(true);
    setMessage(text);
  };

  const handleLogin = async ({ user, token, qrcode }, touchless) => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    localStorage.removeItem('qrcode');

    const finish = () => {
      removeCookies('token', { path: '/' });
      removeCookies('user_id', { path: '/' });
      setLoading(false);
    };

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 70);

    setCookie('token', token, { path: '/', expires });
    setCookie('user_id', user._id, { path: '/', expires });
    setCookie('touchless', touchless, { path: '/', expires });
    if (!touchless) localStorage.setItem('qrcode', JSON.stringify(qrcode));

    const resRole = await axiosGet(`/roles/${user.roleId}`);

    if (resRole.data.data.name !== 'Cliente') {
      createError('Você só pode logar nesta máquina com um usuário cliente. Favor falar com a equipe técnica');

      finish();
      return;
    }

    if (user.machineId == null) {
      createError('Essa conta não está vinculada à uma máquina');

      finish();
      return;
    }

    setLogged(true);

    setUserInfo({
      ...user,
      isAdmin: false,
      qrcode,
    });

    history.push('/');

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await api.post('/login', { email, password });
      handleLogin(res.data.data, false);
    } catch (err) {
      if (err.response?.status === 400) createError('Email ou senha incorretos por favor refaça o login');
      else createError('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
      setLoading(false);
    }
  };

  useEffect(() => {
    const loginWithToken = async (sessionToken) => {
      try {
        const res = await axiosPost('/login/touchless', { sessionToken });

        handleLogin(res.data.data, true);
      } catch (err) {
        if (err.response?.status === 400) createError('Email ou senha incorretos por favor refaça o login');
        else createError('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
        setLoading(false);
      }
    };

    if (location.search) {
      const params = new URLSearchParams(location.search);

      const sessionToken = params.get('sessionToken');

      if (sessionToken) loginWithToken(sessionToken);
    }
  }, [location]);

  const params = new URLSearchParams(location.search);

  const sessionToken = params.get('sessionToken');

  if (sessionToken) {
    return <LoadingTouchless />;
  }

  return (
    <>
      <Container>
        <Content>
          <Logo />
          {message && (
            <Message error={error}>{message}</Message>
          )}
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <SubmitButton htmlType="submit" onClick={handleSubmit} loading={loading}>
              Entrar
            </SubmitButton>
          </Form>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(Auth);
