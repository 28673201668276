import React, {
  memo,
} from 'react';

import {
  Container,
  Logo,
  BackgroundCorners,
} from '../../components/Auth';

import { Message, Content } from './styles';

import { useWindowSize } from '../../hooks/useWindowSize';

function Expired() {
  const size = useWindowSize();

  return (
    <>
      <Container>
        <Content>
          <Logo />
          <Message>Sessão expirada, por favor escaneie o QR Code novamente.</Message>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(Expired);
