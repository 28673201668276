export const getVideoDuration = (fileUrl) => {
  const video = document.createElement('video');

  video.src = fileUrl;

  return new Promise((res) => {
    video.onloadedmetadata = () => {
      res(video.duration);
    };
  });
};
