import React, { useContext } from 'react';

import {
  Content,
  Divider,
} from './styles';

import CartItem from '../CartItem';

import { CartContext } from '../../state/CartContext';

function CartList() {
  const { cartState } = useContext(CartContext);

  return (
    <Content>
      <Divider />
      {Object.entries(cartState).map(([key, value]) => !!cartState[key] && (
        <CartItem
          key={key}
          productId={key}
          itemInfo={value.itemInfo}
          Divider={Divider}
        />
      ))}
    </Content>
  );
}

export default CartList;
