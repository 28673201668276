/* eslint-disable quotes */
import React from 'react';
import {
  Overlay,
} from './styles';

import Loading from '../../components/Loading';

function LoadingOverlay({ ...props }) {
  return (
    <Overlay {...props}><Loading /></Overlay>
  );
}

export default LoadingOverlay;
