import styled, { css } from 'styled-components';
import Colors from '../../styles/Colors';

const AdContainer = styled.section`
  background-image: url(${(props) => props.ad});
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

const Instruction = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 100px;
  height: 100vh;
  position: absolute;
  border: none;
  outline: none;
`;

const IconContainer = styled.div`
  position: fixed;
  top: ${({ hasBillboard }) => (hasBillboard ? '80px' : 0)};
  right: 0;
  margin: 30px 20px;
`;

const IconFrame = styled.img`
  position: relative;
  width: 200px;
`;

const Icon = styled.img`
  position: relative;
  z-index: 2;
  background-color: transparent;
  border-radius: 20px;
  width: 200px;
`;

const IconOff = styled.img`
  position: relative;
  z-index: 2;
  background-color: transparent;
  border-radius: 20px;
  width: 200px;
`;

const QrCodeIcon = styled.img`
  position: absolute;
  z-index: 2;
  background-color: transparent;
  border-radius: 20px;
  width: 150px;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TapIcon = styled.img`
  width: 4rem;
  opacity: 0.8;
  margin-bottom: -15px;
`;

const Text = styled.h2`
  background-color: transparent;
  text-align: center;
  letter-spacing: .15rem;
  font-size: 2rem;
  color: ${Colors.mainWhite};
  margin-bottom: 100px;
  margin-top: 20px;
  width: 80%;
  font-weight: bolder;
  opacity: 0.8;

  @media (max-width: 1024px) {
    background-color: transparent;
    text-align: center;
    letter-spacing: 0rem;
    font-size: 0.9rem;
    color: ${Colors.mainWhite};
    width: 80%;
    margin-bottom: 50px;

    ${({ touchless }) => touchless && css`
      font-weight: bolder;
      white-space: nowrap;
      margin-bottom: -30px;
    `}
  }
`;

const TextMobile = styled.h2`

`;

const AdImage = styled.div`
  background-image: url('${({ src }) => src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;
const Source = styled.source``;

const BillBoard = styled.div`
  opacity: .6;
  background-color: transparent;
  padding-top: .9rem;
  padding-bottom: .9rem;
  font-size: 2.2rem;
  background-color: ${Colors.black};
  color: ${Colors.mainWhite};
  text-transform: uppercase;
  letter-spacing: .08rem;
  font-weight: 600;
  white-space: nowrap;
  position: fixed;
  top: 0;
  width: 100%;
`;

export {
  AdContainer,
  Instruction,
  IconContainer,
  IconFrame,
  Icon,
  IconOff,
  QrCodeIcon,
  Text,
  TextMobile,
  AdImage,
  Video,
  Source,
  BillBoard,
  TapIcon,
};
