import styled from 'styled-components';
import Colors from '../../styles/Colors';

import { LowerAdSection } from '../../styles/globalStyle';

const ProductsSection = styled(LowerAdSection)`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  flex-grow: 1;
  /* padding-top: 3rem; */
  .dsopi{
    background: #fff;
  }

  .dsopi h3{
    letter-spacing: 0rem;
    font-size: 0.8rem;
  }
  .dsopi p{
    letter-spacing: 0rem;
  }
  
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-flow: column;
  width: 100%;
  /* margin-top: 17rem; */
  background-color: ${Colors.background};
  padding-top: ${({ hasFilter }) => (hasFilter ? '0' : '0')};
  margin-bottom: ${({ cartIsEmpty }) => (cartIsEmpty ? '0' : '12rem')};

  max-height: fill-available;
  /* max-height: fit-content; */

  @media (max-width: 1023px) {
    padding-top: ${({ hasFilter }) => (hasFilter ? '0' : '0')};
    /* margin-top: 11rem; */
  }
`;

const ProductsList = styled.section`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  
  width: 95%;
  flex-grow: 1;
  height: min-content;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 0 1rem; */
  }
`;

const Icon = styled.img`
  margin: 0;
`;

const Header = styled.header`
  display: flex;
  justify-content: ${({ isAdmin }) => (isAdmin ? 'flex-end' : 'space-between')};
  width: 100%;
  padding: 1.5rem 2rem;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  @media only screen and (min-width: 600px) {
    display: ${({ link }) => link && 'none'};
  }
`;

const Image = styled.img`
  margin: .6rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Dash = styled.div`
  width: 10rem;
  height: .2rem;
  background-color: ${Colors.secondaryBlue};
`;

const Title = styled.h1`
  color: ${Colors.secondaryBlack};
  height: 2rem;
  text-transform: uppercase;
`;

const Ghost = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, 0.1);
  outline: 1px solid transparent;
  background-color: ${Colors.mainWhite};
  width: 13rem;
  height: 14.6rem;
  max-height: min-content;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: .3rem;
`;

const FilterList = styled.section`
  margin-top: -.5rem;
  position: sticky;
  top: 3rem;
  z-index: 5;
  background-color: ${Colors.background};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: fit-content;
  width: 100%;
  flex-grow: 1;
  overflow-x: auto;
  padding: 0 10px;
  margin-top: 3rem;

  ::-webkit-scrollbar {
    display: none !important;
  }

  @media (min-width: 1024px) {
    padding: 0 3rem;
    margin-top: 5rem;
    top: 5rem;
  }
`;

const CheckoutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 10rem;
  width: 100vw;
  border: none;
  box-shadow: 0 -.4rem .56rem 0 ${Colors.mainBlack};
  background-image: ${Colors.gradiendBackground};

  @media (max-width: 1024px) {
    height: fit-content;
    padding: 10px 0;
  }
`;

const CheckoutText = styled.p`
  font-size: 3rem;
  text-transform: uppercase;
  color: ${Colors.mainWhite};
  text-align: center;
  margin: 0;
  text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
  margin-left: 2rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  
  @media (max-width: 1024px) {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
  }
`;

const CheckoutTotal = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${Colors.mainWhite};
  text-align: center;
  margin: 0;
  text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
  font-weight: 500;
  letter-spacing: 0.2rem;
  left: 10.7rem;
  bottom: 1rem;
  position: absolute;
  z-index: 9999;

  @media (min-width: 1080px) {
    font-size: 3rem;
    left: 12.4rem;
    bottom: 4rem;
  }
  
  @media (max-width: 1024px) {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
    left: 5.7rem;
  }
`;

const CheckoutIcon = styled.img`
  height: 5rem;
  left: 10rem;
  position: absolute;

  @media(max-width: 1024px) {
    height: 25px;
    margin-right: 20px;
    left: 5rem;
    position: absolute;
  }
`;

export {
  ProductsSection,
  ProductsList,
  ContentContainer,
  Icon,
  Header,
  Button,
  Image,
  TitleContainer,
  Dash,
  Title,
  Ghost,
  FilterList,
  CheckoutButton,
  CheckoutText,
  CheckoutTotal,
  CheckoutIcon,
};
