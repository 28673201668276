import cardErrorPng from '../../assets/card-error.png';
import productErrorPng from '../../assets/product-error.png';

const errorText = 'Ops! Desculpe, erro na liberação do produto. O pagamento será estornado.';

const errorMap = {
  card: {
    icon: cardErrorPng,
    text: 'Ops! Desculpe, erro na transação. por favor escolha outra forma de pagamento.',
    buttonText: 'Tentar novamente',
  },
  product: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  UNDEFINED: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  BUSY: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  TIMEOUT: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  ITEM_IN_TRAY: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  ITEM_NOT_IN_TRAY: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
  UNKNOWN_SLOT_ID: { icon: productErrorPng, text: errorText, buttonText: 'Comprar novamente' },
};

export default errorMap;
