import React, {
  useContext,
  useState,
  useEffect,
} from 'react';

import { Redirect, useLocation, useHistory } from 'react-router';
import { useCookies } from 'react-cookie';

import { Button } from 'antd';
import { toast } from 'react-toastify';
import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';
import logotipovend from './Assets/logo-vend-market.svg';
import people01 from './Assets/people01.png';
import background from './Assets/bg-vend-market.jpg';
import UserContext from '../../state/UserContext';

function BemVindo() {
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [machineId, setMachineId] = useState('');
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [cookies, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);
  const location = useLocation();
  const history = useHistory();

  const backNavigation = () => setRedirect('../');
  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const [size, setSize] = useState('large');

  const handleLogin = async ({
    customer, user, token, qrcode,
  }, touchless) => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    removeCookies('customer_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    localStorage.removeItem('qrcode');

    const finish = () => {
      removeCookies('token', { path: '/' });
      removeCookies('user_id', { path: '/' });
      removeCookies('customer_id', { path: '/' });
      setLoading(false);
    };

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 70);

    setCookie('token', token, { path: '/', expires });
    setCookie('user_id', user._id, { path: '/', expires });
    setCookie('customer_id', customer._id, { path: '/', expires });
    setCookie('touchless', touchless, { path: '/', expires });
    if (!touchless) localStorage.setItem('qrcode', JSON.stringify(qrcode));

    const resRole = await axiosGet(`/roles/${user.roleId}`);

    if (resRole.data.data.name !== 'Cliente') {
      toast.error('Você só pode logar nesta máquina com um usuário cliente. Favor falar com a equipe técnica');

      finish();
      return;
    }

    if (user.machineId == null) {
      toast.error('Essa conta não está vinculada à uma máquina');

      finish();
      return;
    }

    // setLogged(true);
    const dadosUserSession = {
      ...user,
      customer,
      isAdmin: false,
      qrcode,
      machineId,
    };

    setUserInfo(dadosUserSession);

    history.push('/');

    setLoading(false);
  };

  const handleAnonimousLogin = async () => {
    const { machineId } = userInfo;
    const res = await api.post('/customers/auth', { email: 'anonimo@vendmach.com', machineId });

    await handleLogin(res.data.data, true);
  };

  useEffect(() => {
    const loginWithToken = async (sessionToken, machineId) => {
      try {
        const res = await axiosPost('/customers/token', { sessionToken, machineId });

        await handleLogin(res.data.data, true);
      } catch (err) {
        if (err.response?.status === 400) toast.error('Email ou senha incorretos por favor refaça o login');
        else toast.error('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
        setLoading(false);
      }
    };

    const checkLogged = async (machineId) => {
      try {
        const sessionToken = cookies?.token;
        if (sessionToken) await loginWithToken(sessionToken, machineId);
      } catch (err) {
        console.error(err);
      }
    };

    if (location.search) {
      const params = new URLSearchParams(location.search);

      const machineIdParam = params.get('mid');
      if (machineIdParam) {
        setMachineId(machineIdParam);
        setUserInfo({
          ...userInfo,
          machineId: machineIdParam,
        });

        checkLogged(machineIdParam);
      }
    }
  }, [location]);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people01})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Seja bem-vindo(a)!</h2>
            <h3>Cadastre-se para receber promoções e descontos exclusivos!</h3>
            <Button type="primary" size={size} block className="azul" onClick={() => goTo('cadastro')}>
              criar minha conta
            </Button>
            <Button size={size} block onClick={() => handleAnonimousLogin()}>
              continuar comprando
            </Button>
            <p className="tem-conta">Já tem uma conta?</p>
            <Button type="primary" shape="round" size={size} className="clique" onClick={() => goTo('validacao-cadastro')}>
              Clique aqui
            </Button>
          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default BemVindo;
