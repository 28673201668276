import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { Redirect } from 'react-router';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Input, Switch } from 'antd';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';
import { getCpf } from '../../services/sintegra';
import logotipovend from './Assets/logo-vend-market.svg';
import people02 from './Assets/people02.png';
import background from './Assets/bg-vend-market.jpg';
import UserContext from '../../state/UserContext';
import whatsapp from '../../services/whatsapp';

function Cadastro() {
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [termos_uso, setTermosUso] = useState(false);
  const [full_name, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const [document_id, setDocumentId] = useState('');
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState('');

  const [userInfo, setUserInfo] = useContext(UserContext);
  const [, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);

  const location = useLocation();
  const history = useHistory();

  const backNavigation = () => setRedirect('../');
  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const [size, setSize] = useState('large');

  const checkHandler = () => {
    setTermosUso(!termos_uso);
  };

  const checkDataSintegra = async () => {
    
    const dados_sintegra = await getCpf({
      cpf: document_id.replace(/\D/g,''), 
      data_nascimento: birthday.replace(/\D/g,''), 
    });

    return dados_sintegra;
  }

  const formatDate = (inputDate) => {
    // Split the input date by '/'
    var parts = inputDate.split('/');
    
    // Rearrange the parts to yyyy-mm-dd format
    var formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
    
    return formattedDate;
  };

  const handleSubmit = async () => {
    try {
      if (full_name === '' || email === '' || document_id === '') {
        toast.error('Todos os campos devem ser preenchidos.');
        setLoading(false);
        return false;
      }

      if (document_id.length < 14) {
        toast.error('Informe o CPF corretamente.');
        setLoading(false);
        return false;
      }

      if (mobile.length < 14) {
        toast.error('Informe o Celular corretamente.');
        setLoading(false);
        return false;
      }

      if (birthday.length < 10) {
        toast.error('Informe a Data de Nascimento corretamente. DD/MM/YYYY');
        setLoading(false);
        return false;
      }

      if (!termos_uso) {
        toast.error('Você deve concordar com os termos de uso para avançar.');
        setLoading(false);
        return false;
      }

      const dados_sintegra = checkDataSintegra(); //{status:'OK'} 

      if(dados_sintegra.status === 'ERROR'){
        toast.error('Erros encontrados: ' . dados_sintegra.message);
        return false;
      }

      const customer = await api.post('/customers', {
        name: full_name, 
        phone: mobile, 
        email, 
        document_id, 
        birthday : formatDate(birthday), 
        gender: dados_sintegra.genero,
      });

      // const customer = {
      //   _id: '6509a9da53f0dd0099cea1eb',
      //   avatar: 'default.png',
      //   gender: null,
      //   birthday: null,
      //   phone_validated: '2023-09-19T14:02:53.851Z',
      //   phone_code_validation: 437609,
      //   name: 'Sandro da Rocha Moura Cavalcanti',
      //   phone: '81997470220',
      //   email: 'sandrocavalcanti@gmail.com',
      //   document_id: '040.073.564-40',
      //   createdAt: '2023-09-19T14:02:02.899Z',
      //   updatedAt: '2023-09-19T14:02:53.859Z',
      // };

      const customerData = customer?.data?.data;

      const responseWpp = await api.post('/notify/send-whatsapp', {
        name: customerData?.name, 
        phone: customerData?.phone, 
        validation_code: customerData?.phone_code_validation, 
      });

      toast.success('Cadastro realizado com sucesso!');

      setRedirect({ pathname: '/ativacao-cadastro', state: customerData });
    } catch (err) {
      console.error(err);
      if (err.response?.status === 400) { toast.error('Já existe um cadastro com este email e telefone'); } else { toast.error('Ocorreu um erro, consulte a equipe de suporte'); }

      setLoading(false);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people02})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Cadastro</h2>
            <h3>Estamos felizes por ter você aqui!</h3>
            <h4>Digite seus dados para criar sua conta.</h4>

            <Input
              size="large"
              name="full_name"
              placeholder="Nome Completo"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
            />
            <InputMask
              mask="999.999.999-99"
              pattern="[0-9]*"
              maskChar=""
              size="large"
              name="document_id"
              placeholder="CPF"
              value={document_id}
              onChange={(e) => setDocumentId(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>

            <InputMask
              mask="(99) 99999-9999"
              type='tel'
              pattern="[0-9]*"
              maskChar="_"
              size="large"
              name="mobile"
              placeholder="Celular"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>

            <InputMask
              mask="99/99/9999"
              type='tel'
              pattern="[0-9]*"
              maskChar=""
              size="large"
              name="birthday"
              placeholder="Data de Nascimento"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            >
              {(inputProps) => <Input {...inputProps} />}
            </InputMask>

            <Input
              size="large"
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="termo">
              <Switch onClick={() => checkHandler()} />
              {' '}
              Aceitar os termos de uso.
            </div>

            <Button type="primary" size={size} block className="azul" onClick={() => handleSubmit()}>
              Confirmar
            </Button>

            <p className="tem-conta">Já tem uma conta?</p>
            <Button type="primary" shape="round" size={size} className="clique" onClick={() => goTo('validacao-cadastro')}>
              Clique aqui
            </Button>
          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default Cadastro;
