import styled, { css } from 'styled-components';

import Colors from '../../../styles/Colors';

export const LoadingIcon = styled.img`
  width: 20rem;
  background-color: white;
`;

export const LoadingText = styled.p`
  width: 80%;
  text-align: center;
  font-size: 3.15rem;
  color: ${Colors.black};
  letter-spacing: 0.13rem;
  margin-bottom: 5rem;

  ${({ small }) => small && css`
    font-size: 2.5rem;
    width: 70%;
  `}

  @media (max-width: 1024px) {
    font-size: 1.3rem;
    margin-bottom: 0;
    letter-spacing: 0.1rem;
    line-height: 1.8rem;

    margin-bottom: 20px;
    width: 90%;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F1F2F3;
  justify-content: center;
`;
