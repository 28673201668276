import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Content = styled.div`
  width: 100%;
  margin-top: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Colors.medium};
`;

export {
  Content,
  Divider,
};
