import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react';

import { axiosGet } from '../services/api';
import socket from '../services/socket';

const UserContext = createContext(false);
const { Consumer: UserConsumer } = UserContext;

function UserProvider({ children }) {
  const storageUser = JSON.parse(localStorage.getItem('userData'));
  const DEFAULT_USER = storageUser || null;
  const [userInfo, _setUserInfo] = useState(DEFAULT_USER);
  const setUserInfo = (value) => {
    _setUserInfo(value);
    localStorage.setItem('userData', JSON.stringify(value));
  };
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {

    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  const getUserInfo = () => {
    const match_costumer = document.cookie.match(/(^| )customer_id=([^;]+)/);

    const match_user = document.cookie.match(/(^| )user_id=([^;]+)/);

    if (match_user) {
      axiosGet(`/users/${match_user[2]}`, setUserInfo, async (user) => {
        const resRole = await axiosGet(`/roles/${user.roleId}`);
        const { data: dataMachine } = await axiosGet(`/machines/${user.machineId}`);
        const { data: dataCompany } = await axiosGet(`/companies/${user.companyId}`);
        const { available } = dataMachine.data || {};
        const { block } = dataCompany.data || {};

        socket.emit('socketMachineRelation', user.machineId, () => {});

        let customer = {};

        if (match_costumer) {
          const resCustomer = await axiosGet(`/customers/${match_costumer[2]}`);
          customer = resCustomer.data.data;
        }

        return {
          ...user,
          isAdmin: resRole.data.data.name === 'admin',
          onlyAds: !(available && !block),
          machine: dataMachine?.data || null,
          customer,
        };
      });
    } else {
      setTimeout(() => {
        getUserInfo();
      }, 1000);
    }
  };

  useEffect(getUserInfo, []);

  useEffect(() => {
    socket.on("machineAdUpdate", async (message) => {
      if (message === userInfo.machine._id) {
        const { data: dataMachine } = await axiosGet(`/machines/${message}`);
        const { data: dataCompany } = await axiosGet(`/companies/${userInfo.companyId}`);
        const { available } = dataMachine.data || {};
        const { block } = dataCompany.data || {};
  
        socket.emit('socketMachineRelation', userInfo.machine._id, () => {});
  
        setUserInfo({
          ...userInfo,
          onlyAds: !(available && !block),
          machine: dataMachine.data,
        });
      }
    });
  }, []);
  

  return (
    <UserContext.Provider value={[{ ...userInfo, isOnline }, setUserInfo, getUserInfo]}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;

export const useUserContext = () => useContext(UserContext);

export {
  UserProvider,
  UserConsumer,
};
