import React from "react";
import InputMask from "react-input-mask";
import Modal from "../../../components/Modal";
import { CardModal } from "../styles";

export default function AddressModal({
    handleSubmit,
    active,
    setActive,
    cepState,
    addressState,
    numeroState,
    complementoState,
}) {
    return (
        <Modal handleSubmit={handleSubmit} active={active} setActive={setActive}>
            <Modal.Title>Atualizar Endereço</Modal.Title>
            <InputMask
                mask="99999-999"
                pattern="[0-9]*"
                maskChar=""
                type='tel'
                size="large"
                name="cep"
                placeholder="CEP *"
                value={cepState.state}
                onChange={(e) => cepState.setState(e.target.value.replace("-", ''))}
                >
                {(inputProps) => <Modal.Input {...inputProps} />}
                </InputMask>
            <CardModal>
            <Modal.Input
            disabled
            size="large"
            name="cidade"
            id="cidade"
            placeholder="Cidade *"
            value={addressState?.state.localidade}
            >
            </Modal.Input>
            <Modal.Input
            disabled
            size="large"
            name="uf"
            id="uf"
            placeholder="UF *"
            value={addressState.state?.uf}
            >
            </Modal.Input>
            </CardModal>
            <Modal.Input
            size="large"
            name="endereco"
            id="endereco"
            placeholder="Endereço *"
            value={addressState?.state.logradouro}
            onChange={(e) => addressState?.setState({...addressState.state, logradouro: e.target.value})}
            >
            </Modal.Input>
            <Modal.Input
            size="large"
            name="bairro"
            id="bairro"
            placeholder="Bairro *"
            value={addressState?.state.bairro}
            onChange={(e) => addressState.setState({...addressState.state, bairro: e.target.value})}
            >
            </Modal.Input>
            <CardModal>
            <InputMask
                mask="999999"
                pattern="[0-9]*"
                maskChar=""
                type='tel'
                size="large"
                name="numero"
                placeholder="Número *"
                value={numeroState.state}
                onChange={(e) => numeroState.setState(e.target.value)}
                >
                {(inputProps) => <Modal.Input {...inputProps} />}
                </InputMask>
                <Modal.Input
                size="large"
                name="complemento"
                id="complemento"
                placeholder="Complemento"
                value={complementoState.state}
                onChange={(e) => complementoState.setState(e.target.value)}
                />
            </CardModal>  
            <Modal.Button>Salvar</Modal.Button>    
        </Modal>)
}