import styled, { createGlobalStyle } from 'styled-components';

const LowerAdSection = styled.section`
  width: 100%;
  display: flex;
  // padding-top: 25vh;
  z-index: 1;
`;

const AdSection = styled.section`
  width: 100%;
  display: flex;
  min-height: 25vh;
  position: fixed;
  max-height: 25vh;
  z-index: 5;
  overflow: hidden;
`;

const PageContent = styled.div`
  height: 100vh;
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    color: #016ec2;
    background: #f5f5f5;

    &.cursor-hide {
      * {
        cursor: none !important;
        ::-webkit-scrollbar {
          cursor: none !important;
        }
      }
      ::-webkit-scrollbar {
        cursor: none !important;
      }
    }
  }
  body, input, button {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  #root {
    margin: 0 0;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  button {
    cursor: pointer;
  }
`;

export {
  LowerAdSection,
  AdSection,
  PageContent,
};
