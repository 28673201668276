import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  flex: 1;

  @media (min-width: 1024px) {
    margin-right: 30px;
  }
`;

const FilterContainer = styled.div`
  width: fit-content;
  height: fit-content;
  padding: .5rem;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
    
  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    margin: 0;
  }
`;

const Filter = styled.button`
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  border: none;
  outline: none;
  
  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 600px) {
    display: ${({ link }) => link && 'none'};
  }
`;

const IconCircle = styled.div`
  width: 10.6rem;
  height: 10.6rem;
  background-color:  ${({ selected }) => (selected ? 'transparent' : Colors.background)};
  border-radius:50%;
  justify-content: center;
  align-items: center;
  display: flex;

  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    margin: 0;
  }
`;

const FilterText = styled.h2`
  font-size: 2.1rem;
  letter-spacing: .08rem;
  display: none;
`;

const Icon = styled.img`
  width: 100%;

  @media (max-width: 1024px) {
    width: 100px;
  }
  @media (max-width: 900px) {
    width: 60px;
  }
`;

const GradientBorder = styled.div`
  /* background-image: ${Colors.gradiendBackground}; */
  border-radius:50%;
  width: 11.2rem;
  height: 11.2rem;
  padding: .3rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    margin: 0;
  }
`;

const Name = styled.span`
  color: ${({ selected }) => (selected ? '#707070' : Colors.mainBlack)};
  font-size: 1.2rem;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  @media (max-width: 900px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  @media (min-width: 1025px) {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
`;

export {
  Container,
  FilterContainer,
  Filter,
  IconCircle,
  FilterText,
  Icon,
  GradientBorder,
  Name,
};
