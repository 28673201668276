import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 103;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Colors.mainWhite};
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 3vh;
  left: 5%;
`;

const HasItemImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;

  @media(min-width: 1024px) {
    width: 180px;
  }
`;

const BaseButton = styled.button`
  margin-top: auto;
  margin-bottom: auto;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const CarouselButton = styled(BaseButton)`
  position: absolute;
  /* top: 50%; */
  width: 15vw;
  height: 15vw;
`;

const NextButton = styled(CarouselButton)`
  right: 3rem;
`;

const PrevButton = styled(CarouselButton)`
  /* top: 50%; */
  width: 15vw;
  height: 15vw;
  left: 3rem;
`;

const Image = styled.img`
  width: 25px;
  
  @media(min-width: 1024px) {
    width: 60px;
  }
`;

const ProductContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  height: 70%;
  width: 100%;
  margin: auto;
`;

const ImageCarouselContainer = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InformationIcon = styled.button`
  border: none;
  background-image: linear-gradient(to top, #00acea, #00efd1);
  border-radius: 50%;
  border: none;
  color: #fff;
  position: absolute;

  width: 25px;
  height: 25px;
  font-size: 1.2rem;

  right: -40px;
  top: -30px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    width: 75px;
    height: 75px;
    font-size: 3.5rem;
    right: -110px;
    top: -70px;
  }
`;

const ProductImage = styled.img`
  /* margin: .6rem; */
  /* height: 75%; */
  width: 80vw;
  margin-bottom: 1rem;

  @media (min-width: 1920px) {
    width: 30vw;
  }
`;

const NameContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  max-width: 80%;
`;

const ProductName = styled.h2`
  font-size: 4rem;
  text-align: center;
  font-weight: bold;
  letter-spacing: .13rem;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    font-size: 1.25rem;
    font-weight: bolder;
    margin-bottom: 20px;
  }
`;

const InfoButton = styled.button`
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: .13rem;
  border: none;
  background-color: transparent;
  color: ${Colors.black};
  text-decoration: underline;
  display: flex;
  align-items: center;
`;

const PriceTag = styled.div`
  top: 0;
  right: 25%;
  margin-bottom: 1rem;
  padding: 2vw 5vw;
  border-radius: 2.5vw;
  line-height: 2.5rem;
  background-image: ${Colors.gradientBlue};

  @media (max-width: 1024px) {
    padding: 0 15px;
  }
`;
const PriceText = styled.p`
  margin: 0;
  font-size: 5vw;
  font-weight: bold;
  color: ${Colors.mainWhite};
  letter-spacing: .15rem;
`;

PriceTag.Text = PriceText;

const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: visible;
`;

const ActionButton = styled(BaseButton)`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  padding-bottom: 3%;
  margin-top: 1vh;

  &:first-child {
    background-image: linear-gradient(to right, #00efd1, #00acea);
  }

  &:last-child {
    background-image: linear-gradient(to right, #00d1b7, #007eac);
    margin-bottom: 1vh;
  }
`;

const ButtonText = styled.p`
  font-size: 5vw;
  text-transform: uppercase;
  color: ${Colors.mainWhite};
  text-align: center;
  margin: 0;
  text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
`;

const InfoContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10.2rem;
  padding-right: 3.7rem;
  padding-left: 3.7rem;
`;

InfoContent.Title = styled(ProductName)`
  max-width: 100%;
  align-text: start;
  text-align: left;
`;

InfoContent.Table = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
`;

const ProductAddContainer = styled.div`
  width: 90%;
  
  display: flex;
  justify-content: space-between;

  margin-top: 50px;

  @media(min-width: 1024px) {
    margin-top: 100px;
  }
`;

const CountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  width: 30%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1px 2px;

  @media(min-width: 1024px) {
    font-size: 3.5rem;
  }
`;

const ChangeNumberButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
`;

const ChangeNumberImage = styled.img`
  width: 20px;

  @media(min-width: 1024px) {
    width: 50px;
  }
`;

const AddProductButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  background-image: linear-gradient(to right, #6cc2ff, #0066b5);
  width: 60%;

  display: flex;
  justify-content: space-between;
  align-items: center;
 
  padding: 5px 20px;
  border-radius: 5px;

  @media(min-width: 1024px) {
    padding: 10px 50px;
  }
`;

const AddTextWithoutPrice = styled.span`
  color: ${Colors.mainWhite};
  font-size: 0.8rem;
  font-size: 1.15rem;
  width: 100%;

  @media(min-width: 1024px) {
    font-size: 3rem;
  }
`;

const AddText = styled.span`
  color: ${Colors.mainWhite};
  font-size: 0.8rem;
  font-size: 1.15rem;
  margin-right: 10px;

  @media(min-width: 1024px) {
    font-size: 3rem;
  }
`;

const AddPrice = styled.span`
  color: ${Colors.mainWhite};
  font-weight: bold;
  font-size: 1.1rem;
  white-space: pre;
  margin-left: 3px;

  @media(min-width: 1024px) {
    font-size: 3rem;
  }
`;

const CheckoutButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 10rem;
  width: 100vw;
  border: none;
  box-shadow: 0 -.4rem .56rem 0 ${Colors.mainBlack};
  background-image: ${Colors.gradiendBackground};

  @media (max-width: 1024px) {
    height: fit-content;
    padding: 10px 0;
  }
`;

const CheckoutText = styled.p`
font-size: 2.7rem;
text-transform: uppercase;
color: ${Colors.mainWhite};
text-align: center;
margin: 0;
text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
margin-left: 2rem;
font-weight: 600;

@media (max-width: 1024px) {
  font-size: 1.1rem;
  font-weight: normal;
  margin: 0;
}
`;

const NutritionalTableModal = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NutritionalTableImage = styled.img`
  width: 80%;
  margin: 100px;
`;

const BackIcon = styled.img`
  width: 100px;
  position: absolute;
  left: 5%;
  top: 50px;

  @media (max-width: 1024px) {
    width: 50px;
    top: 10px;
  }
`;

const Waves = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const CheckoutIcon = styled.img`
height: 5.55rem;

@media(max-width: 1024px) {
  height: 25px;
  margin-right: 20px;
}
`;

export {
  ModalBase,
  CloseButton,
  HasItemImage,
  NextButton,
  PrevButton,
  Image,
  NameContainer,
  ProductName,
  ProductImage,
  ProductContent,
  PriceTag,
  BottomButtonsContainer,
  ActionButton,
  ButtonText,
  InfoContent,
  InfoButton,
  InformationIcon,
  ProductAddContainer,
  CountContainer,
  ChangeNumberButton,
  ChangeNumberImage,
  AddProductButton,
  AddText,
  AddTextWithoutPrice,
  AddPrice,
  CheckoutButton,
  CheckoutText,
  ImageCarouselContainer,
  NutritionalTableModal,
  NutritionalTableImage,
  BackIcon,
  Waves,
  CheckoutIcon,
};
