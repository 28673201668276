import React from 'react';

import { url } from '../../services/api';

import {
  Container,
  FilterContainer,
  Filter,
  IconCircle,
  FilterText,
  Icon,
  GradientBorder,
  Name,
} from './styles';

function ProductFilterCard({
  filterData,
  setSelectedCategory,
  selectedCategory,
  // nutritionInfo
}) {
  const selected = filterData._id === selectedCategory;

  const handleFilter = () => {
    setSelectedCategory((
      selected ? '' : filterData._id
    ));
  };

  return (
    <Container>
      <FilterContainer>
        <Filter onClick={handleFilter}>
          <GradientBorder>
            <IconCircle selected={selected}>
              <Icon src={
                selectedCategory === filterData._id ? (
                  filterData.icon.enabledImage
                ) : (
                  filterData.icon.disabledImage
                )
              }
              />
            </IconCircle>
          </GradientBorder>
          <FilterText>
            {filterData.title}
          </FilterText>
        </Filter>
      </FilterContainer>

      <Name selected={selected}>{filterData.name}</Name>
    </Container>
  );
}

export default ProductFilterCard;
