export default {
  background: '#f4f4f4',
  gradiendBackground: 'linear-gradient(139deg, #00efd1 -22%, #00acea 103%)',
  gradientBlue: 'linear-gradient(131deg, #6cc2ff -11%, #0066b5 85%)',
  blue: '#3fa9f5',
  shadow: '#00000029',
  secondaryBlue: '#016ec2',
  mainWhite: '#fff',
  mainBlack: 'rgba(0, 0, 0, 0.16)',
  statusBackground: 'rgba(0, 0, 0, 0.5)',
  black: '#000000',
  secondaryBlack: '#4d4d4d',
  medium: '#d9d9d9',
};
