import React, { useState } from 'react';
import { Input } from 'antd';
import { VirtualKeyboard } from '..';

function EmbuedInput({
  value,
  setValue,
  onEnter,
  defaultLayout,
  ...props
}) {
  const [active, setActive] = useState(false);

  const handleFocus = () => {
    if (props.handleFocus) props.handleFocus();
    setActive(true);
  };

  const handleBlur = () => {
    if (props.handleBlur) props.handleBlur();
    setActive(false);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      <VirtualKeyboard
        active={active}
        onChange={handleChange}
        onEnter={onEnter}
        defaultLayout={defaultLayout}
      />
    </>
  );
}

export default EmbuedInput;
