import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* padding: 50px 0 0 50px; */
  padding-left: 10rem;

  @media (max-width: 1024px) {
    margin-top: 50px;
    padding-left: 10rem;
  }

  @media (max-width: 900px) {
    margin-top: 0px;
    padding-bottom: 30px;
    padding-left: 8rem;
  }

  @media (max-width: 450px) {
    margin-top: 10px;
    padding-left: 10%;
  }
`;

const BackIcon = styled.img`
  height: 4rem;
  width: 4rem;

  @media (max-width: 1024px) {
    width: 4rem;
    height: 4rem;
  }
  @media (max-width: 850px) {
    width: 2rem;
    height: 2rem;
  }
`;

const Title = styled.h1`
  color: ${Colors.black};
  font-size: 3.15rem;
  letter-spacing: 0.152rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 2rem;
  margin-bottom: auto;
  margin-top: auto;
  font-size: 2rem;
  @media (max-width: 1020px) {
    margin: auto 0;
    height: fit-content;
    font-weight: bolder;
    font-size: 1.1rem;
  }

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

const Subtitle = styled.h3`
  color: ${Colors.secondaryBlue};
  font-size: 2.15rem;
  letter-spacing: 0rem;
  text-transform: uppercase;
  text-decoration: underline;
  margin-left: 2rem;
  margin-bottom: auto;
  margin-top: auto;

  @media (max-width: 1024px) {
    font-size: 1rem;
    margin: auto 0;
    height: fit-content;
    font-weight: bolder;
  }

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export {
  Header,
  BackIcon,
  Title,
  Subtitle,
};
