import React, {
  useContext,
  useState,
  memo,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation, Link } from 'react-router-dom';

import {
  Container,
  Input,
  SubmitButton,
  Form,
  Message,
  BackgroundCorners,
  Content,
} from '../../components/Auth';

import api, { axiosGet, axiosPost } from '../../services/api';
import { useWindowSize } from '../../hooks/useWindowSize';
import UserContext from '../../state/UserContext';
import logoVend from '../../assets/logo.png';

function Auth() {
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [machineId, setMachineId] = useState('');

  const [userInfo, setUserInfo] = useContext(UserContext);
  const [cookies, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const createError = (text) => {
    setError(true);
    setMessage(text);
  };

  const handleLogin = async ({ customer, user, token, qrcode }, touchless) => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    removeCookies('customer_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    localStorage.removeItem('qrcode');

    const finish = () => {
      removeCookies('token', { path: '/' });
      removeCookies('user_id', { path: '/' });
      removeCookies('customer_id', { path: '/' });
      setLoading(false);
    };

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 70);

    setCookie('token', token, { path: '/', expires });
    setCookie('user_id', user._id, { path: '/', expires });
    setCookie('customer_id', customer._id, { path: '/', expires });
    setCookie('touchless', touchless, { path: '/', expires });
    if (!touchless) localStorage.setItem('qrcode', JSON.stringify(qrcode));

    const resRole = await axiosGet(`/roles/${user.roleId}`);

    if (resRole.data.data.name !== 'Cliente') {
      createError('Você só pode logar nesta máquina com um usuário cliente. Favor falar com a equipe técnica');

      finish();
      return;
    }

    if (user.machineId == null) {
      createError('Essa conta não está vinculada à uma máquina');

      finish();
      return;
    }

    //setLogged(true);
    const dadosUserSession = {
      ...user,
      customer,
      isAdmin: false,
      qrcode,
      machineId,
    };

    setUserInfo(dadosUserSession);

    history.push('/');
    console.log('agora');

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await api.post('/customers/auth', { email, password, machineId });
      
      handleLogin(res.data.data, true);
    } catch (err) {
      if (err.response?.status === 400) createError('Email ou senha incorretos por favor refaça o login');
      else createError('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
      setLoading(false);
    }
  };

  useEffect(() => {
    const loginWithToken = async (sessionToken, machineId) => {
      try {
        const res = await axiosPost('/customers/token', { sessionToken, machineId });
        
        await handleLogin(res.data.data, true);
      } catch (err) {
        if (err.response?.status === 400) createError('Email ou senha incorretos por favor refaça o login');
        else createError('O login não pode ser efetuado, consulte a equipe de desenvolvimento');
        setLoading(false);
      }
    };
  
    const checkLogged = async (machineId) => {
      try {
        const sessionToken = cookies?.token;
        if (sessionToken) await loginWithToken(sessionToken, machineId);
  
      } catch (err) {
        console.error(err);
      }
    };

    if (location.search) {
      const params = new URLSearchParams(location.search);

      const machineIdParam = params.get('mid');
      if (machineIdParam) {
        history.push(`/bem-vindo?mid=${machineIdParam}`);
        setMachineId(machineIdParam);
        setUserInfo({
          ...userInfo,
          machineId: machineIdParam,
        });

        checkLogged(machineIdParam);
      }

    }
  }, [location]);

  return (
    <>
      <Container>
        <Content>
          <img src={logoVend} style={{ 'width':'30%' }}></img>
          <br></br>
          <h1>
            <span style={{fontWeight:'bold', color:'#949FB3'}}>Bem-vindo(a) à</span> <br/>
            <span style={{fontWeight:'bold', color:'#2D9BF0'}}>VendMach</span>
          </h1>
          {message && (
            <Message error={error}>{message}</Message>
          )}
          <Form onSubmit={handleSubmit}>
            <Input
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <div style={{display:'flex'}}>
              <Checkbox>Lembre-me</Checkbox>
              <Link to="/forgot_password" style={{ textAlign:'right', flex:1 }}>
                Esqueci minha senha
              </Link>
            </div> */}
            <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit} loading={loading}>
              Entrar
            </SubmitButton>
            <br/>
            <div style={{textAlign:'center', fontWeight:'bold'}}>
              <span>Novo usuário? </span>
              <Link to="/cadastro">Cadastre-se</Link>
            </div>
          </Form>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(Auth);
