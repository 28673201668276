import React from 'react';
import { Spin, notification } from 'antd';

import { pixTexts } from '../../texts.json';

import {
  SmallerText,
  PaymentLinkContainer,
  LinkIconContainer,
  LinkOutlinedComponent,
  PaymentLink,
  Icon,
  LoadingQrCodeContainer,
  LoadingQrCodeText,
} from './styles';

export default function PixScreen({ isTouchless, qrCodeImage, qrCodeLink }) {
  const displayType = isTouchless ? 'mobile' : 'vendingMachine';

  const handleCopy = async () => {
    if (qrCodeLink) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(qrCodeLink);
        notification.success({ message: 'Código copiado!' });
      }
    }
  };

  return (
    <>
      <SmallerText>
        1)
        {' '}
        {pixTexts[displayType][0]}
      </SmallerText>

      {isTouchless && (
        <PaymentLinkContainer onClick={handleCopy}>
          <LinkIconContainer>
            <LinkOutlinedComponent />
          </LinkIconContainer>
          <PaymentLink href="https://pix.bcb.gov.br/qr/VM" target="blank">
            {qrCodeLink || <Spin size="small" spinning />}
          </PaymentLink>
        </PaymentLinkContainer>
      )}

      <SmallerText>
        2)
        {' '}
        {pixTexts[displayType][1]}
      </SmallerText>

      {!isTouchless && (
        <>
          {qrCodeImage ? (
            <Icon src={qrCodeImage} />
          ) : (
            <LoadingQrCodeContainer>
              <LoadingQrCodeText>
                Estamos carregando o seu qrcode
              </LoadingQrCodeText>
              <Spin size="large" spinning />
            </LoadingQrCodeContainer>
          )}
        </>
      )}

      <SmallerText>
        3)
        {' '}
        {pixTexts[displayType][2]}
      </SmallerText>
    </>
  );
}
