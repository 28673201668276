import React, {
  useEffect,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';

import {
  TopAd,
} from '../../components';

import { sucessPayment, sucessPaymentSampling } from '../../texts.json';

import {
  Content,
  Text,
  Icon,
  Button,
} from './styles';

import sucessIcon from './Assets/ic-sacola@3x.png';
import { useDifferentTimeoutLimit } from '../../state/TimerContext';
import { useUserContext } from '../../state/UserContext';

function SuccessPayment() {
  useDifferentTimeoutLimit(180);
  const [cookies] = useCookies(['touchless', 'customer_id']);
  const [redirect, setRedirect] = useState(null);
  const [userData] = useUserContext();

  const buyAgainAction = () => {
    if (userData?.machine?.type === 'Vendmach') {
      if (cookies.touchless === 'true') {
        setRedirect('/logout');
      } else {
        setRedirect('/');
      }
    } else if (userData.machine?.type === 'Totemach') {
      setRedirect('/');
    }
  };

  useEffect(() => {
    setTimeout(buyAgainAction, 5000);
  }, []);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      {userData?.machine?.sampling !== true && (
      <Button onClick={buyAgainAction}>
        COMPRAR NOVAMENTE
      </Button>
      )}

      <TopAd hasStatusBar={false} />
      <Content>
        <Text>
          { userData?.machine?.sampling !== true ? sucessPayment : sucessPaymentSampling }
        </Text>
        <Icon src={sucessIcon} />
      </Content>
    </>
  );
}

export default SuccessPayment;
