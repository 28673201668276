import React, {
  useContext, useEffect, useState, memo,
} from 'react';
import Marquee from 'react-double-marquee';
import { useCookies } from 'react-cookie';

import { axiosGet } from '../../services/api';

import { clickToBuyMobile } from '../../texts.json';

import AdContext from '../../state/AdContext';
import UserContext from '../../state/UserContext';
import { LazyLoader, StatusBar } from '../../components';
import {
  AdContainer,
  AdImage,
  Instruction,
  IconContainer,
  IconFrame,
  Icon,
  IconOff,
  QrCodeIcon,
  TapIcon,
  Text,
  Video,
  Source,
  BillBoard,
} from './styles';

import touchIcon from '../../assets/touch.svg';
import connectionErrorIcon from '../../assets/connection-error.svg';
import qrcodeFrameIcon from '../../assets/qrcode-frame.svg';
import busyTerminalIcon from '../../assets/busy-terminal.svg';
import TimerContext from '../../state/TimerContext';
import socket from '../../services/socket';

function Test({ billboard }) {
  return (
    <BillBoard>
      <Marquee
        speed={0.1}
        direction="left"
      >
        {billboard.map((ad) => <span style={{ marginRight: '2rem' }} key={ad}>{ad}</span>)}
      </Marquee>
    </BillBoard>
  );
}

const MemoBillboard = memo(Test);

function AdScreen() {
  const { ads, setAds } = useContext(AdContext);
  const [userContext] = useContext(UserContext);
  const { currentFullscreenAd, billboardAdList } = ads;
  const [billboard, setBillboard] = useState([]);
  const [qrcodeImage, setQrcodeImage] = useState(
    userContext?.qrcode?.image
    || (localStorage.getItem('qrcode') && JSON.parse(localStorage.getItem('qrcode')).image),
  );
  const [onlyAds, setOnlyAds] = useState(true);
  const [busy, setBusy] = useState(false);
  const [machine, setMachine] = useState(null);

  const [currentAd, setCurrentAd] = useState(currentFullscreenAd);

  const { timer, setTimer, resetTimer } = useContext(TimerContext);
  const [cookies, setCookie] = useCookies(['touchless','noConnection']);
  const [isTouchless, setIsTouchless] = useState(cookies?.touchless === 'true' ? true : false);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const getCompanyAndMachine = async () => {
      if (!userContext) return;

      const { companyId, machineId, onlyAds: updatedOnlyAds } = userContext;

      setOnlyAds(updatedOnlyAds);

      const array = await Promise.all([
        axiosGet(`/companies/${companyId}`),
        axiosGet(`/machines/${machineId}`),
      ]);

      const [
        { data: companyData },
        { data: machineData },
      ] = array;

      const { data: company } = companyData;
      const { data: machineInfo } = machineData;

      setMachine(machineInfo);

      const { block } = company;
      const { available } = machineInfo;

      setOnlyAds(!(available && !block));
    };

    getCompanyAndMachine();
  }, [userContext]);

  useEffect(() => {
    if (userContext?.qrcode?.image && qrcodeImage !== userContext?.qrcode?.image) {
      setQrcodeImage(userContext?.qrcode?.image);
    }
  }, [userContext, qrcodeImage]);

  useEffect(() => {
    const billboardResult = billboardAdList.map((specificAd) => specificAd.name);

    setBillboard(billboardResult.length !== 1 ? billboardResult : ([
      billboardResult[0],
      billboardResult[0],
    ]));
  }, [billboardAdList]);

  useEffect(() => {
    const updateQrcode = ({ machineId, qrcode }) => {
      if (machineId === userContext.machineId) {
        setQrcodeImage(qrcode.image);
        localStorage.setItem('qrcode', JSON.stringify(qrcode));
        setBusy(true);
      }
    };

    const handleLogout = (machineId) => {
      if (machineId === userContext.machineId) {
        setBusy(false);
      }
    };

    socket.on('logoutTouchless', handleLogout);
    socket.on('loginTouchless', updateQrcode);

    return () => {
      socket.off('logoutTouchless', handleLogout);
      socket.off('loginTouchless', updateQrcode);
    };
  }, [userContext]);

  useEffect(() => {
    if (cookies?.touchless === 'true') {
      resetTimer();
      socket.emit('clientVisit');
      setTimer((prevTimer) => ({
        ...prevTimer,
        onlyMenu: true,
        showSleep: false,
      }));
    }

  }, [cookies, timer]);

  useEffect(() => {
    setCurrentAd(null);
  }, [currentFullscreenAd]);

  useEffect(() => {
    if (currentAd === null) {
      setAds({ ...ads, ...currentFullscreenAd });
      setCurrentAd(currentFullscreenAd);

    }
  }, [currentAd, currentFullscreenAd]);

  useEffect(() => {
    setIsOnline(userContext.isOnline);

    setCookie('noConnection', !isOnline);
  }, [userContext]);

  const isMp4 = currentAd?.ext === 'mp4' || currentAd?.ext.includes('mp4');
  const noConnection = cookies.noConnection === 'true';
  const shouldShowMenu = !noConnection && !busy && machine?.signage === false;

  const clickAdHandler = (e) => {
    let retorno = true;
    if (shouldShowMenu) {
      resetTimer();
    }

    return retorno;
  };

  return (
    <AdContainer onMouseDown={clickAdHandler}>
      {!!billboard.length && (
        <MemoBillboard billboard={billboard} />
      )}
      {currentAd === null
        ? <LazyLoader />
        : (
          <>
            {!isMp4
            && <AdImage src={currentAd.adUrl} />}
            {isMp4
            && (
            <Video muted autoPlay loop>
              <Source src={currentAd.adUrl} type="video/mp4" />
            </Video>
            )}
          </>
        )}
        <IconContainer hasBillboard={!!billboard.length} style={{display:isOnline ? 'none' : 'block'}}>
          <IconOff src={connectionErrorIcon} />
        </IconContainer>
      {busy && (
        <IconContainer hasBillboard={!!billboard.length}>
          <Icon src={busyTerminalIcon} />
        </IconContainer>
      )}
      {shouldShowMenu && isOnline && (
        <>
          {onlyAds 
          || (
            <Instruction>
              {!isTouchless && qrcodeImage && (
                <IconContainer hasBillboard={!!billboard.length}>
                  <IconFrame src={qrcodeFrameIcon} />
                  <QrCodeIcon src={qrcodeImage} />
                  {/* <Text /> */}
                </IconContainer>
              )}
              {isTouchless && (
                <>
                  <TapIcon src={touchIcon} />
                  <Text>
                    {clickToBuyMobile}
                  </Text>
                </>
              )}
            </Instruction>
          )}
        </>
      )}
      <StatusBar />
    </AdContainer>
  );
}

export default AdScreen;
