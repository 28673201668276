import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { MenuOutlined } from '@ant-design/icons';

import {
  Container,
  Child1,
  Text,
  LogoIcon,
} from './styles';

import logo from './assets/mendmach_logo.png';

const StatusBar = ({ top }) => {
  const [today, setToday] = useState({});
  const [redirect, setRedirect] = useState(null);

  const handleClickMenu = async () => {
    setRedirect('/customer_menu');
  };

  useEffect(() => {
    const updateDate = () => {
      const addZero = (number) => (number >= 10 ? number : `0${number}`);

      const newToday = new Date();

      const day = newToday.getDay();
      const date = newToday.getDate();
      const month = newToday.getMonth();
      const hours = addZero(newToday.getHours());
      const minutes = addZero(newToday.getMinutes());

      setToday({
        day,
        date,
        month,
        hours,
        minutes,
      });
    };

    updateDate();

    const timer = setInterval(
      updateDate,
      1000,
    );

    return () => {
      clearInterval(timer);
    };
  }, []);

  const {
    hours,
    minutes,
  } = today;

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Container top={top}>
      <LogoIcon src={logo} />

      <Child1 top={top}>
        <MenuOutlined onClick={handleClickMenu}/>
        <Text>{`${hours}:${minutes}`}</Text>
      </Child1>
    </Container>
  );
};

export default StatusBar;
