import React from 'react';

import {
  StyledModal,
  ErrorContainer,
  ModalIcon,
  Title,
  ButtonContainer,
  TryAgain,
  Cancel,
} from './styles';

function ErrorModal({
  visible,
  error,
  onTryAgain,
  onCancel,
}) {
  return (
    <StyledModal visible={visible} footer={null}>
      <ErrorContainer>
        <ModalIcon src={error.icon} />
        <Title>{error.text}</Title>
        <ButtonContainer>
          <TryAgain
            onClick={onTryAgain}
          >
            {error.buttonText}
          </TryAgain>
          <Cancel
            onClick={onCancel}
          >
            Cancelar
          </Cancel>
        </ButtonContainer>
      </ErrorContainer>
    </StyledModal>
  );
}

export default ErrorModal;
