import React, {
  useState,
  useContext,
  useEffect,
} from 'react';

import { Redirect } from 'react-router';
import { Button } from 'antd';
import { UserOutlined, CreditCardOutlined, LoginOutlined } from '@ant-design/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import UserContext from '../../state/UserContext';


import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
} from './styles';

import logotipovend from './Assets/logo-vend-market.svg';
import people02 from './Assets/people02.png';
import background from './Assets/bg-vend-market.jpg';

function CustomerMenu() {
  const [redirect, setRedirect] = useState(null);
  const [size, setSize] = useState('large');
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [showButtonLock, setShowButtonLock] = useState(false);

  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const openLock = async () => {
    toast.info('A porta será destravada em instantes...');

    try {
      const url = 'https://api-mqtt.homelabstecnologia.com.br/sw-module?apiKey=a28ff5b0-0ce5-4ecd-934d-36b8eba1d3c9';
      const { data } = await axios.post(url, {
        equipment: userInfo?.machine?.lock_number,
        command: '1',
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      toast.success('Porta destravada com sucesso!');
      console.log(data);
    } catch (error) {
      console.error(error);
      toast.error('Ocorreu um erro ao destravar a porta. Tente novamente.');
    }
  };

  useEffect(() => {
    const lockNumber = userInfo?.machine?.lock_number;
    const showLock = (typeof lockNumber === 'undefined') || lockNumber === '' ? false : true;
    setShowButtonLock(showLock);
  }, [userInfo]);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people02})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Menu</h2>

            <h4>Clique sobre a opção desejada.</h4>

            <br />
            <Button type="primary" size={size} icon={<LoginOutlined />} className={showButtonLock ? 'verde' : 'hide'} block onClick={() => openLock()}>
              Abrir Porta
            </Button>
            <Button type="primary" size={size} icon={<UserOutlined />} className='azul' block onClick={() => goTo('customer_mydata')}>
              Meus Dados
            </Button>
            <Button type="primary" size={size} icon={<CreditCardOutlined />} className='azul' block onClick={() => goTo('customer_cards')}>
              Meu Cartão
            </Button>
            <Button type="primary" size={size} className='vermelho' block onClick={() => goTo('')}>
              Cancelar minha conta
            </Button>
            <Button type="default" size={size} block onClick={() => goTo('')}>
              Voltar
            </Button>
          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default CustomerMenu;
