import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Container = styled.div`
  height: 4.3rem;
  width: 13rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    height: fit-content;
    width: fit-content;
  }
`;

const ProductAmountContainer = styled.div`
  height: 4.3rem;
  width: 4.3rem;
  background-image: ${Colors.gradientBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5rem !important;

  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    padding: 5px 13px;
    margin: 0 3px;
  }

  @media (max-width: 320px) {
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    margin: 0 3px;
  }
`;

const ProductAmount = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  color: ${Colors.mainWhite};
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 0.8rem;
    width: fit-content;
  }

  @media (max-width: 320px) {
    font-size: 0.7rem;
    width: fit-content;
  }
`;

const ControlIcon = styled.img`
  height: 3.6rem;
  width: 3.6rem;

  @media (max-width: 1024px) {
    width: 25px; 
    height: 25px; 
  }

  @media (max-width: 1024px) {
    width: 15px; 
    height: 15px; 
  }
`;

export {
  Container,
  ProductAmountContainer,
  ProductAmount,
  ControlIcon,
};
