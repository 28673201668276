import styled from 'styled-components';
import Colors from '../../styles/Colors';

import {
  Header as BaseHeader,
} from '../../components/Header/styles';

const CartSection = styled.section`
  padding: 3.7rem;
  padding-bottom: 27rem;
  position: relative;
  width: 100%;
  overflow-y: auto;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  flex-grow: 1;
  padding-top: 10rem;

  @media (max-width: 1024px) {
    padding-top: 5rem;
  }

  @media (max-width: 320px) {
    padding-top: 4rem;
  }
`;

const Header = styled(BaseHeader)`
  z-index: 5;
  position: fixed;
  padding: 3.7rem;
  margin: -3.7rem;
  margin-bottom: -3rem;
  padding-bottom: 3rem;
  top: unset;
  background-color: ${Colors.background};

  @media (max-width: 1024px) {
    padding: 0;
    padding-left: 30px;
    margin: 0;
    margin-top: 30px;
  }

  @media (max-width: 320px) {
    margin-top: 20px;
  }
`;

export {
  Header,
  CartSection,
  Content,
};
