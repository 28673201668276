import React from 'react';

import {
  Card,
  Title,
  Price,
  Image,
  CardContainer,
  OutOfStockOverlay,
  TextContainer,
} from './styles';

import ProductModal from '../ProductModal';
import { useUserContext } from '../../state/UserContext';

function ProductCard({
  product,
  active,
  setActive,
  nextId,
  prevId,
  setRedirect,
}) {
  const outOfStock = (product.stock < 1);
  const [userData] = useUserContext();

  const openProductModal = () => {
    if (outOfStock) {
      return; // Do nothing if product out of stock
    }
    setActive(product._id);
  };

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <CardContainer>
      <Card onClick={openProductModal}>
        {
          outOfStock && <OutOfStockOverlay />
        }
        <Image src={product.image} />

        <TextContainer>
          <Title>{product.name}</Title>

          {userData?.machine?.sampling !== true && (
            <Price>
              {formatter.format(product?.price)}
            </Price>
          )}
        </TextContainer>
      </Card>

      <ProductModal
        active={active}
        setActive={setActive}
        product={product}
        nextId={nextId}
        prevId={prevId}
        setRedirect={setRedirect}
      />
    </CardContainer>
  );
}

export default ProductCard;
