import React, {
  useContext,
  useState,
  memo,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Container,
  Logo,
  Input,
  SubmitButton,
  BackButton,
  Form,
  Message,
  BackgroundCorners,
  Content,
} from '../../components/Auth';
import { Checkbox, Button, Col, Row } from 'antd';

import api, { axiosGet, axiosPost } from '../../services/api';
import { useWindowSize } from '../../hooks/useWindowSize';
import UserContext from '../../state/UserContext';

function Auth({ setLogged }) {
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [termos_uso, setTermosUso] = useState(false);
  const [full_name, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const [document_id, setDocumentId] = useState('');
  const [birthday, setBirthday] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirm, setPasswordConfirm] = useState('');

  const [userInfo, setUserInfo] = useContext(UserContext);
  const [, setCookie, removeCookies] = useCookies(['token', 'user_id', 'qrcode', 'touchless']);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const createError = (text) => {
    setError(true);
    setMessage(text);
  };

  const checkHandler = () => {
    setTermosUso(!termos_uso)
  }

  const handleLogin = async ({ customer, user, token, qrcode }, touchless) => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    removeCookies('customer_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    localStorage.removeItem('qrcode');

    const finish = () => {
      removeCookies('token', { path: '/' });
      removeCookies('user_id', { path: '/' });
      removeCookies('customer_id', { path: '/' });
      setLoading(false);
    };

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 70);

    setCookie('token', token, { path: '/', expires });
    setCookie('user_id', user._id, { path: '/', expires });
    setCookie('customer_id', customer._id, { path: '/', expires });
    setCookie('touchless', touchless, { path: '/', expires });
    if (!touchless) localStorage.setItem('qrcode', JSON.stringify(qrcode));

    const resRole = await axiosGet(`/roles/${user.roleId}`);

    if (resRole.data.data.name !== 'Cliente') {
      createError('Você só pode logar nesta máquina com um usuário cliente. Favor falar com a equipe técnica');

      finish();
      return;
    }

    if (user.machineId == null) {
      createError('Essa conta não está vinculada à uma máquina');

      finish();
      return;
    }

    //setLogged(true);

    setUserInfo({
      ...user,
      customer,
      isAdmin: false,
      qrcode,
    });

    history.push('/mobile_validate');

    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (full_name == "" || email == "" || password == "") {
        createError('Todos os campos devem ser preenchidos');
        setLoading(false);
        return false;
      }

      if (password.length < 6) {
        createError('A senha deve ter no mínimo 6 caracteres');
        setLoading(false);
        return false;
      }

      if (password !== password_confirm) {
        createError('A senha e a confirmação não são iguais');
        setLoading(false);
        return false;
      }

      if (!termos_uso) {
        createError('Você deve concordar com os termos de uso para avançar');
        setLoading(false);
        return false;
      }

      const { machineId } = userInfo;
      const customer = await api.post('/customers', { name: full_name, phone: mobile, email, password });
      const res = await api.post('/customers/auth', { email, password, machineId });
      
      handleLogin(res.data.data, true);
    } catch (err) {
      if (err.response?.status === 400) createError('Já existe um cadastro com este email e telefone');
      else createError('Ocorreu um erro, consulte a equipe de suporte');
      setLoading(false);
    }

  };

  return (
    <>
      <Container>
        <Content>
          <h1>
            <span style={{fontWeight:'bold', color:'#949FB3'}}>Cadastre-se</span>
          </h1>
          {message && (
            <Message error={error}>{message}</Message>
          )}
          <Form onSubmit={handleSubmit}>
            <Input
              name="full_name"
              placeholder="Nome Completo"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
            />
            <Input
              name="email"
              type='email'
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              name="mobile"
              type='phone'
              placeholder="Celular"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            {/* <Input
              name="birthday"
              type='date'
              placeholder="Data de nascimento"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            /> */}
            {/* <Input
              name="document_id"
              placeholder="CPF"
              value={document_id}
              onChange={(e) => setDocumentId(e.target.value)}
            /> */}
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              name="password_confirm"
              type="password"
              placeholder="Confirmação de Senha"
              value={password_confirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <Checkbox
              name="termos_uso"
              checked={termos_uso}
              onChange={checkHandler}
            >
              Concordo com os <span style={{fontWeight:'bold'}}>termos de uso</span>.
            </Checkbox>
            <Row justify="space-around">
              <Col span={10}>
                <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit} loading={loading} block>
                  Cadastrar
                </SubmitButton>
              </Col>
              <Col span={10}>
                <BackButton type="danger" block onClick={() => history.goBack()}>
                  Voltar
                </BackButton>
              </Col>
            </Row>
          </Form>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(Auth);
