import React, {
  useState,
  useEffect,
} from 'react';

import { Redirect } from 'react-router';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
} from './styles';

import {
  BackIcon,
  Title,
  Subtitle,
} from '../../components/Header/styles';

import iconBack from '../../components/Header/Assets/ic-back.png';

import { cart as cartText, addMoreItems as addMoreItemsText } from '../../texts.json';

import {
  CartList,
  CartCheckout,
  TopAd,
} from '../../components';

import { axiosGet } from '../../services/api';

function Cart() {
  const [redirect, setRedirect] = useState(null);
  const backNavigation = () => setRedirect('../');
  const goToCheckout = (celular) => {
    setRedirect({ pathname: '/checkout', state: { celular } });
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const { data: itemsData } = await axiosGet('/items');

      setItems(itemsData.data);
    };

    fetchItems();
  }, []);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <>
      {/* <TopAd hasStatusBar={false} /> */}
      <LowerAdSection>
        <CartSection>
          <Header>
            <BackIcon src={iconBack} onClick={backNavigation} />
            <div>
              <Title>{cartText}</Title>
              <Subtitle onClick={backNavigation}>{addMoreItemsText}</Subtitle>
            </div>
          </Header>
          <Content>
            <CartList />
            <CartCheckout items={items} goToCheckout={goToCheckout} />
          </Content>
        </CartSection>
      </LowerAdSection>
    </>
  );
}

export default Cart;
