import React, { useContext } from 'react';

import addIcon from './Assets/ic-add.png';
import rmvIcon from './Assets/ic-rmv.png';

import { CartContext } from '../../state/CartContext';

import {
  Container,
  ProductAmount,
  ProductAmountContainer,
  ControlIcon,
} from './styles';

function CartProductAmount({
  itemInfo,
}) {
  const { cartState, cartDispatch } = useContext(CartContext);

  const { amount } = cartState[itemInfo.productId._id] || {};

  if (!amount) return null;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Container>
      <ControlIcon
        src={rmvIcon}
        onClick={() => {
          cartDispatch({
            type: 'REMOVE',
            itemInfo,
            value: itemInfo.productId._id,
            price: itemInfo.price,
            itemId: itemInfo._id,
          });
        }}
      />
      <ProductAmountContainer>
        <ProductAmount>{amount}</ProductAmount>
      </ProductAmountContainer>
      <ControlIcon
        src={addIcon}
        onClick={() => {
          cartDispatch({
            type: 'ADD',
            itemInfo,
            value: itemInfo.productId._id,
            price: itemInfo.price,
            itemId: itemInfo._id,
          });
        }}
      />
    </Container>
  );
}

export default CartProductAmount;
