import React, { useContext, useState, useEffect } from 'react';

import {
  Content,
  ContentVideo,
} from './styles';

import StatusBar from '../StatusBar';
import AdContext from '../../state/AdContext';
import LazyLoader from '../LazyLoader';

function TopAd({ hasStatusBar = true }) {
  const { ads, setAds } = useContext(AdContext);

  const { currentTopAd } = ads;
  const [currentAd, setCurrentAd] = useState(currentTopAd);

  const hasAds = currentTopAd?.adUrl !== undefined ? true : false;

  const isVideo = currentTopAd?.adUrl.includes('.mp4');

  useEffect(() => {
    setCurrentAd(null);
  }, [currentTopAd]);

  useEffect(() => {
    if (currentAd === null) {
      setAds({ ...ads, ...currentTopAd });
      setCurrentAd(currentTopAd);

    }
  }, [currentAd, currentTopAd]);

  return (
    <>
      {/* {hasStatusBar && <StatusBar top />}
      {currentAd === null ? 
       <LazyLoader small/> :
        isVideo ? (
        <ContentVideo muted autoPlay loop>
          <source src={currentAd?.adUrl} type="video/mp4" />
        </ContentVideo>
      ) : (
        hasAds && <Content src={currentAd?.adUrl} />
      )} */}
    </>
  );
}

export default TopAd;
