import styled from 'styled-components';

const Lower = styled.img`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const Upper = styled.img`
  position: fixed;
  top: 0;
  width: 100%;
`;

const Back = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

export {
  Lower,
  Upper,
  Back,
};
