import styled, { css } from 'styled-components';

import Colors from '../../styles/Colors';

export const Container = styled.div`
  background: ${Colors.gradientBlue};
  width: 100%;
  color: ${Colors.mainWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  ${({ top }) => (top ? 'top: 0' : 'bottom: 0')};
  z-index: 10;
  padding: 5px 15px;
  font-size: 1rem;
  height: 3rem;
  @media(min-width: 1024px) {
    padding: 15px 30px;
    font-size: 3rem;
    height: 5rem;
  }
`;

export const Child1 = styled.div`
  display: flex;
  align-items: center;

    width: ${({ top }) => (top ? '100%' : 'fit-content')};
    justify-content: ${({ top }) => (top ? 'space-between' : 'space-between')};
`;

export const Text = styled.span`
  ${({ margin }) => margin && css``}
`;

export const Child2 = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoIcon = styled.img`
  width: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media(min-width: 1024px) {
    width: 45px;
  }
`;
