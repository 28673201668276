import React from 'react';

import {
  Lower,
  Upper,
  Back,
} from './styles';

import upperIcon from './Assets/Upper.svg';
import lowerIcon from './Assets/Lower.svg';

function WaveBackground() {
  return (
    <>
      <Back>
        <Upper src={upperIcon} />
        <Lower src={lowerIcon} />
      </Back>
    </>
  );
}

export default WaveBackground;
