import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 103;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Colors.mainBlack};
`;

const ModalContent = styled.form`
  position: relative;
  width: 50%;
  /*max-width: 26.6rem;*/
  max-height: 40rem;
  margin: auto;
  padding: 0 2.6rem 2.6rem;
  background-color: ${Colors.mainWhite};
  overflow: auto;
  text-align: center;
  @media only screen and (max-width: 600px) {
    position: relative;
    width: 85%;
    max-height: 40rem;
    margin: auto;
    padding: 0 2.6rem 2.6rem;
    background-color: ${Colors.mainWhite};
    overflow: auto;
    text-align: center; 
    }
`;

const FormInput = styled.input`
  padding: .6rem;
  width: 100%;

  background-color: ${Colors.background};

  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.mainWhite};

  outline-style: none;

  color: ${Colors.secondaryBlue};

  margin: .6rem 0;

  &::placeholder {
    color: ${Colors.secondaryBlue};
  }
`;

const FormButton = styled.button`
  width: 100%;
  outline: none;
  background-color: ${Colors.secondaryBlue};
  border: none;
  padding: .5rem 0;
  color: white;
  border-radius: .2rem;
  margin-top: 2.6rem;
  opacity: ${({ opacity }) => opacity};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  background-color: white;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

const Image = styled.img`
  margin: .6rem;
`;

const Title = styled.h2`
  color: ${Colors.secondaryBlue};
  text-transform: uppercase;
  font-weight: bold;
  margin: 2rem 0;
`;

const SubTitle = styled.h4`
  color: ${Colors.secondaryBlue};
  text-transform: uppercase;
  font-weight: bold;
  margin: 1rem 0;
`;

const FileButton = styled.button`
  outline: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.04);
  width: 100%;
  padding: .6rem;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const FileText = styled.p`
  margin: 0 .6rem;
`;

const Textarea = styled.textarea`
  padding: .6rem;
  width: 100%;

  background-color: ${Colors.background};

  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.secondaryBlue};

  outline-style: none;

  color: ${Colors.secondaryBlue};

  margin: .6rem 0;

  &::placeholder {
    color: ${Colors.secondaryBlue};
  }
  resize: none;
`;

const Label = styled.p`
  color: ${Colors.secondaryBlue};
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  text-align: left;

  &:first-child {
    margin: 0;
  }
`;

const colourStyles = {
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    border: `1px solid ${Colors.secondaryBlue}`,
    cursor: 'pointer',
    margin: '1rem 0',
    '&:hover': {
      border: `1px solid ${Colors.secondaryBlue}`,
    },
  }),
  option: (base, state) => ({
    ...base,
    color: Colors.secondaryBlue,
    backgroundColor: state.isSelected ? '#eee' : base.backgroundColor,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => { },
  dropdownIndicator: (base) => ({ ...base, '& svg': { fill: Colors.secondaryBlue } }),
  singleValue: (base) => ({ ...base, color: Colors.secondaryBlue }),
  placeholder: (base) => ({ ...base, color: Colors.secondaryBlue }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '7rem',
    overflow: 'auto',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '0rem',
    borderRadius: '0rem',
  }),
};

export {
  ModalBase,
  ModalContent,
  FormInput,
  FormButton,
  CloseButton,
  Image,
  Title,
  SubTitle,
  FileButton,
  FileText,
  Textarea,
  Label,
  colourStyles,
};
