import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

function HideCursorChecker() {
  const [cookies] = useCookies(['hideCursor']);

  useEffect(() => {
    document.body.className = cookies.hideCursor === 'true' ? 'cursor-hide' : '';
  }, [cookies.hideCursor]);

  return null;
}

export default HideCursorChecker;
