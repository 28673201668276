import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import {
  Container,
  ButtonLink,
  OpenNav,
  ButtonLink2,
} from './styles';
import UserContext from '../../state/UserContext';

import Account from './assets/account.svg';
import Business from './assets/bolsa.svg';
import Group from './assets/users.svg';
import Machine from './assets/Group.svg';
import DNS from './assets/dns.svg';
import Products from './assets/reader.svg';
import Vendas from './assets/loyalty.svg';
import Exit from './assets/exit.svg';

import Logo from './assets/logo.svg';
import logoM from './assets/Logo_M.svg';

function NavBar({ setLogged }) {
  const [visible, setVisible] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [,, removeCookies] = useCookies(['token']);
  const [userInfo] = useContext(UserContext);

  useEffect(() => {
    const conditionalNavLink = () => (
      userInfo?.isAdmin ? (
        { image: Business, name: 'empresas' }
      ) : (
        { image: Group, name: 'grupos', link: `empresas/${userInfo?.companyId}` }
      )
    );

    const conditionalPath = () => (
      userInfo?.isAdmin ? 'produtos' : 'itens'
    );

    setRoutes([
      { image: Account, name: 'usuarios' },
      conditionalNavLink(),
      { image: Machine, name: 'maquinas' },
      { image: DNS, name: 'anuncios' },
      { image: Products, name: conditionalPath() },
      { image: Vendas, name: 'vendas' },
    ]);
  }, [userInfo]);

  function close() {
    setVisible(false);
  }

  function open(e) {
    if (e) {
      e.preventDefault();
    }

    setVisible(true);
  }

  const logoutUser = () => {
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    setLogged(false);
  };

  return (
    <>
      <OpenNav type="button" onClick={visible ? close : open}>
        <img id="logoM" src={logoM} alt="logo m" />
      </OpenNav>
      <Container>
        <Drawer
          title={(
            <Link to="/" onClick={close} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonLink2
                onClick={close}
              />
              <img style={{ marginLeft: 15, marginTop: '1.3rem' }} alt="admin" src={Logo} />
            </Link>
          )}
          placement="left"
          className="example"
          closable={false}
          onClose={close}
          visible={visible}
          zIndex={101}
          key="placement"
          width={300}
          drawerStyle={{ borderColor: 'red', borderWidth: 25 }}
          headerStyle={{ borderBottomStyle: 'none', marginTop: 10 }}
          bodyStyle={{
            display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column',
          }}
          footer={(
            <ButtonLink onClick={logoutUser} style={{ marginLeft: 15 }}>
              <img src={Exit} alt="Sair" />
              Sair
            </ButtonLink>
          )}
          footerStyle={{
            borderTopStyle: 'none',
            marginBottom: 10,
          }}
        >
          {routes.map((route) => (
            <Link to={`/${route.link || route.name}`} key={route.name} onClick={close}>
              <ButtonLink>
                <img src={route.image} alt={route.name} />
                {capitalizeFirstLetter(route.name)}
              </ButtonLink>
            </Link>
          ))}
        </Drawer>
      </Container>
    </>
  );
}

export default NavBar;
