import React, { useState, useEffect } from 'react';

import {
  Container,
  Child1,
  Text,
  Child2,
  LogoIcon,
} from './styles';

import logo from './assets/mendmach_logo.png';

const StatusBar = ({ top }) => {
  const days = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
  const months = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAIO',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  const [today, setToday] = useState({});

  useEffect(() => {
    const updateDate = () => {
      const addZero = (number) => (number >= 10 ? number : `0${number}`);

      const newToday = new Date();

      const day = newToday.getDay();
      const date = newToday.getDate();
      const month = newToday.getMonth();
      const hours = addZero(newToday.getHours());
      const minutes = addZero(newToday.getMinutes());

      setToday({
        day,
        date,
        month,
        hours,
        minutes,
      });
    };

    updateDate();

    const timer = setInterval(
      updateDate,
      1000,
    );

    return () => {
      clearInterval(timer);
    };
  }, []);

  const {
    day,
    date,
    month,
    hours,
    minutes,
  } = today;

  const temperature = 15.5;

  return (
    <Container top={top}>
      {!top && (
        <Child2>{`${days[day]}, ${date} ${months[month]}`}</Child2>
      )}

      <LogoIcon src={logo} />

      <Child1 top={top}>
        {top && (
          <Text margin>
            {temperature > 10 ? temperature.toFixed(0).toString() : temperature.toString().replace('.', ',')}
            {' '}
            Cº
          </Text>
        )}

        <Text>{`${hours}:${minutes}`}</Text>
      </Child1>
    </Container>
  );
};

export default StatusBar;
