import { createContext } from 'react';

export const CartContext = createContext();

export const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      return (() => {
        const hasValue = Object.keys(state).includes(action.value);

        return {
          ...state,
          [action.value]: hasValue
            ? { ...action, amount: state[action.value].amount + 1 }
            : { ...action, amount: 1 },
        };
      })();
    case 'ADD_AMOUNT':
      return (() => {
        const hasValue = Object.keys(state).includes(action.value);

        return {
          ...state,
          [action.value]: hasValue
            ? { ...action, amount: state[action.value].amount + action.amount }
            : { ...action, amount: action.amount },
        };
      })();
    case 'REMOVE':
      return (() => {
        const hasValue = Object.keys(state).includes(action.value);
        const newState = { ...state };

        if (state[action.value].amount === 1) {
          const { [action.value]: removedKey, ...rest } = newState;

          return rest;
        }

        return {
          ...state,
          [action.value]: hasValue
            ? { ...action, amount: state[action.value].amount - 1 }
            : { ...action, amount: 0 },
        };
      })();
    case 'REMOVE_ALL':
      return {
        ...state,
        [action.value]: { amount: 0 },
      };
    case 'CLEAN_CART':
      return {};
    default:
      return state;
  }
};
