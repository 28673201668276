import styled from 'styled-components';

import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  margin: auto;
  width: 90% !important;

  .ant-modal-content {
    height: 60vh;
    border-radius: 40px;
    overflow: hidden;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 0px;
      height: 100%;
    }
  }

  @media (max-width: 1024px) {
    .ant-modal-content {
      height: max-content;
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const ModalIcon = styled.img`
  width: 240px;
  margin: 200px auto 80px;

  @media (max-width: 1024px) {
    width: 120px;
    margin: 100px auto 60px;
  }

  @media (min-width: 1920px) {
    width: 120px;
    margin: 0px auto 40px;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1.92px;
  text-align: center;
  color: black;
  text-transform: uppercase;
  width: 80%;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TryAgain = styled.button`
  background-image: linear-gradient(81deg, #00efd1 -16%, #00acea 71%);
  width: 100%;
  height: 140px;
  color: white;
  padding: 0;
  margin: 0;
  outline: none;
  text-transform: uppercase;
  border: none;
  font-size: 40px;

  @media (max-width: 1024px) {
    font-size: 20px;
    height: 50px;
  }
`;

export const Cancel = styled(TryAgain)`
  background-color: #ececec;
  background-image: none;
  color: #4d4d4d;
`;
