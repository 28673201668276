import React from 'react';
import LoadingScreen from '../PaymentInstruction/LoadingScreen';

function LoadingTouchless() {
  return (
    <LoadingScreen text={['Por favor aguarde enquanto efetuamos o login']} />
  );
}

export default LoadingTouchless;
