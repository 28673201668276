import React, {
  useContext,
  useState,
  memo,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import api, { axiosGet, axiosPost } from '../../services/api';
import { useWindowSize } from '../../hooks/useWindowSize';
import UserContext from '../../state/UserContext';

import {
  Container,
  Logo,
  Input,
  SubmitButton,
  BackButton,
  Form,
  Message,
  BackgroundCorners,
  Content,
} from '../../components/Auth';
import { Checkbox, Button, Col, Row, Typography } from 'antd';
const { Text, Link } = Typography;


function MobileValidate() {
  const size = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [mobile_code, setMobileCode] = useState('');
  const [userInfo, setUserInfo] = useContext(UserContext);

  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const history = useHistory();

  const createError = (text) => {
    setError(true);
    setMessage(text);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { customer } = userInfo;
    if(!mobile_code) createError('Informe o código recebido')
    if(mobile_code != customer.phone_code_validation) createError('Código informado é inválido')

    await api.patch('/customers/'+customer._id, { phone_validated: new Date() });

    history.push('/');
  };

  return (
    <>
      <Container>
        <Content>
          <h1>
            <span style={{fontWeight:'bold', color:'#949FB3'}}>Validar Celular</span>
          </h1>
          {message && (
            <Message error={error}>{message}</Message>
          )}
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col><Text strong>Informe o código recebido</Text></Col>
            </Row>
            <Row>
              <Col><Text>Enviamos um código via WhatsApp para o celular informado. Por favor, informe abaixo este código.</Text></Col>
            </Row>
            <Input
              name="mobile_code"
              type="number"
              placeholder="Código"
              value={mobile_code}
              onChange={(e) => setMobileCode(e.target.value)}
            />
            <Row justify="space-around">
              <Col span={10}>
                <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit} loading={loading} block>
                  Validar
                </SubmitButton>
              </Col>
              <Col span={10}>
                <BackButton type="danger" block onClick={() => history.goBack()}>
                  Voltar
                </BackButton>
              </Col>
            </Row>
          </Form>
        </Content>
      </Container>
      <BackgroundCorners size={size} position="TopLeft" />
      <BackgroundCorners size={size} position="TopRight" />
      <BackgroundCorners size={size} position="BottomLeft" />
      <BackgroundCorners size={size} position="BottomRight" />
    </>
  );
}

export default memo(MobileValidate);
