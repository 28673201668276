import styled from 'styled-components';

import Colors from '../../../../client/src/styles/Colors';

const ModalBase = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${Colors.mainBlack};
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;
  max-width: 26.6rem;
  height: 20%;
  min-height: 13.3rem;
  margin: auto;
  padding: 0 2.6rem 1.3rem;
  border-radius: .2rem;
  background-color: ${Colors.mainWhite};
  overflow: auto;
  text-align: center;
`;

const Button = styled.button`
  border: none;
  outline: none;
  background-color: white;
`;

const Image = styled.img`
  cursor: pointer;
  margin: .6rem;
  position: absolute;
  top: 0;
  right: 0;
`;

const Title = styled.h2`
  color: ${Colors.secondaryBlue};
  text-align: left;
`;

const Text = styled.p`
  color: black;
  text-align: left;
  span {
    color: black;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled.button`
  border: none;
  background-color: white;
  outline: none;
  color: ${Colors.secondaryBlue};
  text-transform: uppercase;
  text-decoration: ${({ main }) => main && 'underline'};

  &:hover {
    text-decoration: underline;
  }
`;

export {
  ModalBase,
  ModalContent,
  Button,
  Image,
  Title,
  Text,
  ButtonContainer,
  SubmitButton,
};
