import React from 'react';

import {
  ModalBase,
  ModalContent,
  Button,
  Image,
  Title,
  Text,
  ButtonContainer,
  SubmitButton,
} from './styles';
import { useErrorContext } from '../../state/ErrorContext';

import closeIcon from './Assets/close.svg';

function WarnModal({
  title,
  text,
  accept = () => { },
  deny = () => { },
  active,
  setActive,
}) {
  const [, setError] = useErrorContext();
  const handleCloseModal = (e) => {
    if (e.target.id === 'shade') setActive(false);
  };

  const handleSubmit = (cb) => {
    try {
      cb();
    } catch (err) {
      setError({
        type: 'modal/delete',
        message: err.response?.data?.message || err.message,
        status: err.response?.status || false,
        url: null,
      });
    }
    setActive(false);
  };

  return (
    <ModalBase id="shade" active={active} onClick={handleCloseModal}>
      <ModalContent>
        <Button type="button" onClick={() => setActive(false)}>
          <Image src={closeIcon} />
        </Button>
        <div>
          <Title>{title}</Title>
          <Text>
            {text}
          </Text>
        </div>
        <ButtonContainer>
          <SubmitButton onClick={() => handleSubmit(accept)}>Sim</SubmitButton>
          <SubmitButton main onClick={() => handleSubmit(deny)}>Não</SubmitButton>
        </ButtonContainer>
      </ModalContent>
    </ModalBase>
  );
}

export default WarnModal;
