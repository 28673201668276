import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
} from 'react';
import { Redirect } from 'react-router';

import {
  ProductCard,
  LazyLoader,
  ProductFilterCard,
  TopAd,
  StatusBarCustomer,
} from '../../components';

import { useHistory } from 'react-router';

import { axiosGet } from '../../services/api';

import { CartContext } from '../../state/CartContext';
import TimerContext from '../../state/TimerContext';
import UserContext from '../../state/UserContext';

import { checkOutList } from '../../texts.json';

import {
  ProductsSection,
  ProductsList,
  ContentContainer,
  Ghost,
  FilterList,
  CheckoutButton,
  CheckoutText,
  CheckoutTotal,
  CheckoutIcon,
} from './styles';

import checkoutImage from './Assets/cart.png';
import 'react-simple-keyboard/build/css/index.css';
import { useCookies } from 'react-cookie';

function ProductList(
  {
    products,
    showGhost,
    setRedirect,
    active,
    setActive,
    categories,
    selectedCategory,
  },
) {
  const count = products.length;
  
  return (
    <ProductsList>
      {products
        .filter((product) => {
          if (!selectedCategory) {
            return true;
          }

          const index = categories.findIndex((category) => (
            category._id === selectedCategory
          ));

          return categories[index].items.some((item) => product._id === item._id);
        })
        //.filter((product, index) => (
          //products.slice(0, index).findIndex(({ _id }) => _id === product._id) === -1))
        .filter((value, index, self) =>
          index === self.findIndex((t) => (
            t._id === value._id
        )))
        .map((product, index) => {
          let prevIndex = index - 1;
          let nextIndex = index + 1;
          if (prevIndex < 0) {
            prevIndex = count - 1;
          }
          if (nextIndex === count) {
            nextIndex = 0;
          }
          const nextId = products[nextIndex]._id;
          const prevId = products[prevIndex]._id;

          return (
            <ProductCard
              key={product._id}
              product={product}
              active={active}
              setActive={setActive}
              nextId={nextId}
              prevId={prevId}
              setRedirect={setRedirect}
            />
          );
        })}
      {showGhost && (
        <Ghost>
          <LazyLoader />
        </Ghost>
      )}
    </ProductsList>
  );
}

const ProductListMemoized = memo(ProductList);

function Products() {
  const { timer, resetTimer } = useContext(TimerContext);
  const [userInfo] = useContext(UserContext);

  const { cartState } = useContext(CartContext);
  const [cartIsEmpty, setCartIsEmpty] = useState(true);

  const [search] = useState('');
  const [, setProducts] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [filter, setFilter] = useState(null);
  const [active, setActive] = useState(null);
  const [showGhost, setShowGhost] = useState(false);
  const [totalItensCart, setTotalItensCart] = useState(0);
  const [showTopAd, setShowTopAd] = useState(true);
  const [showTopMenu, setShowTopMenu] = useState(false);

  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [cookies] = useCookies(['noConnhection','customer_id']);
  const history = useHistory();

  const goToCart = () => setRedirect('/carrinho');

  const loadList = useCallback(() => {
    setProducts(null);
    setShowGhost(false);
  }, [setProducts, setShowGhost]);

  useEffect(() => {
    setCartIsEmpty(Object.keys(cartState).length === 0);
  }, [Object.keys(cartState).length]);
  useEffect(() => {
    loadList();
  }, [search, filter, loadList]);

  useEffect(() => {
    const totalItensCart = Object.values(cartState).reduce((accumulator, object) => {
      return accumulator + object['amount'];
    }, 0);
    setTotalItensCart(totalItensCart);
    setCartIsEmpty(Object.values(cartState).length === 0
    || Object.values(cartState).reduce((a, b) => a.amount + b.amount, 0) === 0);
  }, [cartState]);

  useEffect(() => {
    if (!userInfo) return;
    if (!userInfo.machineId) return;

    const fetchProducts = async () => {
      const { data: machineData } = await axiosGet(`/machines/${userInfo.machineId}?populate=true`);

      if (!machineData) return;

      const { slots } = machineData?.data;

      // const validSlots = slots.filter(({ itemId }) => itemId);
      const validSlots = machineData?.data.type === 'Totemach' 
        ? slots
        : slots.filter((slot) => slot.quantity > 0);

      const itemsInfo = validSlots.filter(slot => slot.itemId !== null && slot.itemId).map(({ itemId }) => itemId);
      const productsInfo = itemsInfo.map(({ productId }) => productId);

      const mergedItemsAndProducts = itemsInfo.map((itemInfo, index) => ({
        ...productsInfo[index],
        ...itemInfo,
      }));

      setItems(mergedItemsAndProducts);
    };

    const fetchCategories = async () => {
      const { data: categoriesData } = await axiosGet('/categories?populate=true');

      if (!categoriesData) return;

      setCategories(categoriesData.data);
    };

    const redirectIfOffLine = () => {
      if(userInfo.isOnline === false){
        console.log(`Offline, redirecionando para os ads`);
        timer.showSleep = true;
      }
    };

    fetchProducts();
    fetchCategories();
    redirectIfOffLine();
  }, [userInfo]);

  useEffect(() => {
    if (cookies?.noConnection === true) {
      setRedirect('/');
    }

    if (cookies?.customer_id && userInfo.customer.email !== 'anonimo@vendmach.com') {
      setShowTopAd(false);
      setShowTopMenu(true);
    }
  }, [cookies]);

  if (redirect) return <Redirect to={redirect} />;

  const sortedCategories = categories.sort((categoryA, categoryB) => {
    if (categoryA.position < categoryB.position) {
      return -1;
    }
    if (categoryA.position > categoryB.position) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      {showTopMenu && <StatusBarCustomer top={true}/>}
      {(items?.findIndex((e) => e._id === active) === -1)
         && <TopAd hasStatusBar={false} />}
      <ProductsSection>
        {!!sortedCategories.length && (
          <FilterList>
            {sortedCategories.map((filterData) => (
              <ProductFilterCard
                key={filterData._id}
                filterData={filterData}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            ))}
          </FilterList>
        )}

        <ContentContainer cartIsEmpty={cartIsEmpty} hasFilter>
          {items ? (
            <ProductListMemoized
              products={items}
              showGhost={showGhost}
              setRedirect={setRedirect}
              active={active}
              setActive={setActive}
              resetTimer={resetTimer}
              categories={sortedCategories}
              selectedCategory={selectedCategory}
            />
          ) : <LazyLoader />}
          {!cartIsEmpty
            && (
            <CheckoutButton onClick={goToCart}>
              <CheckoutIcon src={checkoutImage} />
              <CheckoutTotal>{totalItensCart}</CheckoutTotal>
              <CheckoutText>{checkOutList}</CheckoutText>
            </CheckoutButton>
            )}
        </ContentContainer>
      </ProductsSection>
    </>
  );
}

export default Products;
