import React from 'react';
import {
  Header,
  BackIcon,
  Title,
} from './styles';

import iconBack from './Assets/ic-back.png';

function HeaderWithBack({
  title,
  backNavigation,
}) {
  return (
    <Header>
      <BackIcon src={iconBack} onClick={backNavigation} />
      <Title>{title}</Title>
    </Header>
  );
}

export default HeaderWithBack;
