import React, {
  createContext,
  useState,
} from 'react';

const CouponContext = createContext(false);
const { Consumer: CouponConsumer } = CouponContext;

function CouponProvider({ children }) {
  const [coupon, setCoupon] = useState(null);

  return (
    <CouponContext.Provider value={[coupon, setCoupon]}>
      {children}
    </CouponContext.Provider>
  );
}

export default CouponContext;

export {
  CouponProvider,
  CouponConsumer,
};
