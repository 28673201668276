import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: ${Colors.black};
  opacity: 0.5;
`;

export {
  Overlay,
};