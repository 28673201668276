import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import UserContext from '../state/UserContext';

import {
  Products,
  Cart,
  Checkout,
  Cadastro,
  PaymentInstruction,
  SuccessPayment,
  AdScreen,
} from '../pages';
import TimerContext from '../state/TimerContext';

function LoggedRoutes() {
  const { timer } = useContext(TimerContext);
  const [userInfo] = useContext(UserContext);

  if (timer.showSleep || userInfo?.onlyAds) {
    return (
      <Switch>
        <PrivateRoute path="*" component={AdScreen} />
      </Switch>
    );
  }

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Products} />
      <PrivateRoute exact path="/carrinho" component={Cart} />
      <PrivateRoute exact path="/cadastro" component={Cadastro} />
      <PrivateRoute exact path="/checkout" component={Checkout} />
      <PrivateRoute exact path="/payment" component={PaymentInstruction} />
      <PrivateRoute exact path="/success" component={SuccessPayment} />
      <PrivateRoute exact path="/logout" />
    </Switch>
  );
}

export default LoggedRoutes;
