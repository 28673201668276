import styled, { css } from 'styled-components';
import Colors from '../../styles/Colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 3.7rem; */

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const OptionsContainer = styled.div`
  padding: 8rem 2rem 0rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;

  @media only screen and (max-width: 1024px) {
    padding: 4rem 2rem 0rem;
  }

  @media (min-width: 1920px) {
    padding: 0;
    width: 50%;
  }
`;

export const BaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
`;

export const OptionButtonWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: max-content;
`;

export const Button = styled(BaseButton)`
  background-color: ${Colors.background};
  position: relative;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    filter: grayscale(1);
    cursor: not-allowed;
  `}
`;

export const Icon = styled.img`
  width: 50%;
  height: 50%;
  margin: auto;

  @media only screen and (max-width: 900px) {
    min-width: 124px;
    min-height: 124px;
    object-fit: contain;
  }
`;

export const OptionDescription = styled.p`
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 34px;
  letter-spacing: 1.57px;
  color: #5f5f5f;
  text-transform: uppercase;
  margin: 1.75rem 0;
  text-align: center;

  @media only screen and (max-width: 900px) {
    font-size: 0.85rem;
    line-height: 15px;
    margin: 0.85rem 0;
  }
`;

export const OptionList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 600px){
    padding-left: 1.5rem;
    padding-top: 3rem;
  }
`;

export const UniqueOptionList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const FooterCancelButton = styled(Button)`
  position: fixed;
  width: 100vw;
  height: 8rem;
  bottom: 0;
  left: 0;
  font-size: 2rem;
  background-color: #ececec;
  color: #4d4d4d;
  text-transform: uppercase;
  font-weight: 600;

  @media only screen and (max-width: 900px) {
    height: min-content;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.875rem;
  }
`;