import React, {
  createContext,
  useRef,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router';

import useRecursiveTimeout from './recursiveTimeout';
import ad from '../assets/ad.png';
import { AreYouThereModal } from '../components';
import { CartContext } from './CartContext';

const TIME_WITHOUT_ACTION = 60; // seconds
const TIMER_STEP = 1000; // 1 segundos

const BASE_TIMER = {
  showSleep: true,
  showModal: false,
  onlyMenu: false,
  oldDate: new Date(),
  ad: {
    image: ad,
  },
};

const TimerContext = createContext(BASE_TIMER);
const { Consumer: TimerConsumer } = TimerContext;

function TimerProvider({ children }) {
  const [timer, setTimer] = useState(BASE_TIMER);
  const { cartDispatch } = useContext(CartContext);
  const history = useHistory();
  const counterTime = useRef(TIME_WITHOUT_ACTION);
  const stopCount = useRef(false);

  const setAd = () => {
    const nowSeconds = new Date().getTime() / 1000;
    const oldSeconds = timer.oldDate.getTime() / 1000;

    if (
      (nowSeconds - oldSeconds) >= counterTime.current
      && timer.showModal === true
      && timer.showSleep === false
      && stopCount.current === false
      && timer.onlyMenu === false
    ) {
      setTimer((prevTimer) => ({
        ...prevTimer,
        showSleep: true,
        showModal: false,
      }));
    }
  };

  const resetTimer = () => {
    setTimer((prevTimer) => ({
      ...prevTimer,
      oldDate: new Date(),
      showSleep: false,
      showModal: false,
    }));
  };

  const checkTimer = () => {
    const nowSeconds = new Date().getTime() / 1000;
    const oldSeconds = timer.oldDate.getTime() / 1000;

    if (
      (nowSeconds - oldSeconds) >= counterTime.current
      && timer.showModal === false
      && timer.showSleep === false
      && stopCount.current === false
      && timer.onlyMenu === false
    ) {
      setTimer((prevTimer) => ({
        ...prevTimer,
        showSleep: false,
        showModal: true,
      }));
    }
  };

  useRecursiveTimeout(checkTimer, TIMER_STEP);

  const value = {
    timer,
    setTimer,
    resetTimer,
    checkTimer,
    setAd,
    setTimeoutLimit: (time) => { counterTime.current = time; },
  };

  const closeModal = () => {
    setTimer((prevTimer) => ({ ...prevTimer, showModal: false }));
    resetTimer();
  };

  const resetAndGoToMenu = () => {
    setAd();
    cartDispatch({ type: 'CLEAN_CART' });
    history.push('/');
  };

  return (
    <TimerContext.Provider value={value}>
      {children}
      {timer.showModal && (
        <AreYouThereModal closeModal={closeModal} onTimeUp={resetAndGoToMenu} />
      )}
    </TimerContext.Provider>
  );
}

export default TimerContext;

export function useDifferentTimeoutLimit(timeLimit) {
  const { setTimeoutLimit } = useContext(TimerContext);

  useEffect(() => {
    setTimeoutLimit(timeLimit);

    return () => setTimeoutLimit(TIME_WITHOUT_ACTION);
  }, []);
}

export {
  TimerProvider,
  TimerConsumer,
};
