import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import inputMask from "simple-keyboard-input-mask";

import { Wrapper, NumberWrapper } from './styles';

function VirtualKeyboard({
  active,
  onChange,
  onEnter,
  defaultLayout = 'default',
}) {
  const [currentLayout, setCurrentLayout] = useState(defaultLayout);

  const handleKeyPress = (value) => {
    if (!value) return null;

    if (value === '{shift}' || value === '{lock}') {
      return setCurrentLayout(currentLayout === 'default' ? 'shift' : 'default');
    }

    if (value === '{enter}') return null;

    const returnMap = {
      '{bksp}': (prevValue) => prevValue.slice(0, -1),
      '{tab}': (prevValue) => `${prevValue} `,
      '{space}': (prevValue) => `${prevValue} `,
    };

    if (returnMap[value]) return onChange(returnMap[value]);

    return onChange((prevValue) => `${prevValue || ''}${value}`);
  };

  const handleKeyReleased = (value) => {
    //if (value === '{enter}') onEnter();
  };

  return (
    <Wrapper active={active}>
      <Keyboard
        layoutName={currentLayout}
        onKeyPress={handleKeyPress}
        onKeyReleased={handleKeyReleased}
        preventMouseDownDefault
        layout={{
          default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space}"
          ],
          shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {shift}",
            ".com @ {space}"
          ]
        }}
        display={{
          '{bksp}': 'Limpar',
          '{enter}': ' Ok ',
          '{tab}': 'Tab',
          '{lock}': 'Caps',
          '{shift}': 'Shift',
          '{space}': 'Espaço'
        }}
        modules={[inputMask]}
        inputMask={{
          "number": {
            mask: '(99)99999-9999',
            regex: /^[0-9]+$/
          }}
        }
      />
    </Wrapper>
  );
}

function VirtualNumberKeyboard({
  active,
  onChange,
  onEnter,
  maxLength
}) {

  const handleKeyPress = (value) => {
    if (!value) return null;

    if (value === '{enter}') return null;

    const returnMap = {
      '{bksp}': (prevValue) => prevValue.slice(0, -1),
    };

    if (returnMap[value]) return onChange(returnMap[value]);

    return onChange((prevValue) => `${prevValue || ''}${value}`);
  };

  const handleKeyReleased = (value) => {
    //if (value === '{enter}') onEnter();
  };

  return (
    <NumberWrapper active={active}>
      <Keyboard
        layoutName={'number'}
        onKeyPress={handleKeyPress}
        onKeyReleased={handleKeyReleased}
        maxLength={maxLength}
        preventMouseDownDefault
        layout={{
          number: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
        }}
        display={{
          '{bksp}': 'Limpar',
          '{enter}': ' Ok ',
        }}
        modules={[inputMask]}
        inputMask={{
          "number": {
            mask: '(99)99999-9999',
            regex: /^[0-9]+$/
          }}
        }
      />
    </NumberWrapper>
  );
}

export { VirtualKeyboard, VirtualNumberKeyboard };
