import styled, { keyframes } from 'styled-components';
import { Progress as AntProgress } from 'antd';

import Colors from '../../styles/Colors';

export const timer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const Timer = styled.div`
  position: absolute;
  bottom: 0;
  display: list-item;
  background-image: ${Colors.gradiendBackground};
  width: 100%;
  height: .8rem;
  border-radius: 0 2rem 2rem 0;
  animation: ${timer} ${({ duration }) => duration}s linear 0s 1 reverse forwards;
`;

export const ModalBase = styled.div`
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 50rem;
  background-color: ${Colors.background};
  color: ${Colors.black};
  border-radius: 1rem;
  font-size: 5rem;
  letter-spacing: .08rem;
  margin: auto;
  overflow: hidden;
  max-width: 90%;

  @media only screen and (max-width: 1024px) {
    width: 30rem;
    font-size: 1rem;
  }

  @media only screen and (max-width: 900px) {
    width: 20rem;
    font-size: 1rem;
  }
`;

export const Overlay = styled.div`
  background-color: ${Colors.mainBlack};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 1.33;
  letter-spacing: 1.92px;
  margin: 0;
  text-align: center;
  margin-bottom: 5.375rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 900px) {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const Button = styled.button`
  background-image: linear-gradient(81deg, #00efd1 -16%, #00acea 71%);
  width: 100%;
  height: 146px;
  color: white;
  padding: 0;
  margin: 0;
  outline: none;
  text-transform: uppercase;
  border: none;
  font-size: 40px;
  font-weight: 600;

  @media only screen and (max-width: 1024px) {
    font-size: 2rem;
    height: 6rem;
  }

  @media only screen and (max-width: 900px) {
    font-size: 1rem;
    height: 4rem;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.25rem;
  height: 33.25rem;
  border-radius: 50%;
  background-image: linear-gradient(-90deg, #00efd1 -40%, #00acea 168%);
  margin: 2.9375rem 0 2.3125rem;

  @media only screen and (max-width: 1024px) {
    height: 18rem;
    width: 18rem;
    margin: 2rem 0 1.5rem;
  }

  @media only screen and (max-width: 900px) {
    height: 10rem;
    width: 10rem;
    margin: 2rem 0 1.5rem;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 98%;
  border-radius: 50%;
  background-color: ${Colors.background};
`;

export const Progress = styled(AntProgress)`
  .ant-progress-text {
    font-size: 7.8125rem;
    color: black !important;
  }

  @media only screen and (max-width: 1024px) {
    .ant-progress-text {
      font-size: 3.5rem;
    }
    .ant-progress-inner.ant-progress-circle-gradient {
      height: 16rem !important;
      width: 16rem !important;
    }
  }

  @media only screen and (max-width: 900px) {
    .ant-progress-text {
      font-size: 2.6125rem;
    }
    .ant-progress-inner.ant-progress-circle-gradient {
      height: 9.1rem !important;
      width: 9.1rem !important;
    }
  }
`;
