import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useCookies } from 'react-cookie';

import socket from './services/socket';
import Routes from './routes/index';
import GlobalStyle from './styles/globalStyle';

import 'react-toastify/dist/ReactToastify.css';
import 'react-simple-keyboard/build/css/index.css';
import 'antd/dist/antd.css';
import { ErrorProvider } from './state/ErrorContext';

function App() {
  const [cookies] = useCookies(['user_id', 'touchless']);

  useEffect(() => {
    const handleClick = () => {
      const userId = cookies?.user_id ?? null;
      const touchless = cookies?.touchless ?? null;

      socket.emit('clientAction', userId, 'other', touchless);
    };

    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);
  
  return (
    <ErrorProvider>
      <Routes />
      <GlobalStyle />
      <ToastContainer 
        autoClose={5000} 
      />
    </ErrorProvider>
  );
}

export default App;
