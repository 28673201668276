import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Card = styled.div`
  height: 14.2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1024px) {
    height: fit-content;
    justify-content: space-between;
  }
`;

const Title = styled.h3`
  max-width: 20rem;
  font-size: 2.5rem;
  letter-spacing: 0.064rem;
  font-weight: 600;
  margin: 0;
  margin-left: 1.95rem;
  color: ${Colors.secondaryBlue};

  @media (max-width: 1024px) {
    font-size: 1rem; 
    margin-left: 0;
  }

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

const ImageContainer = styled.div`
  height: 9.4rem;
  width: 9.4rem;
  display: flex;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.medium};
  margin: 0 20px;

  @media (max-width: 1024px) {
    width: fit-content;
    height: fit-content;
    min-width: auto;
  }
`;

const Image = styled.img`
  max-width: 10rem;
  max-height: 10rem;
  
  @media (max-width: 1024px) {
    width: 70px;
  }

  @media (max-width: 320px) {
    max-width: 3rem;
    max-height: 3rem;
  }
`;

const RightContainer = styled.div`
  width: 16.4rem;
  margin-left: auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 1rem;
  /* height: 100%; */
  align-items: center;

  @media (max-width: 1024px) {
    width: 5rem;
    min-width: auto;
    margin-left: 5px;
    gap: 0rem;
  }
`;

const Price = styled.h2`
  font-size: 2.5rem;
  letter-spacing: .076rem;
  color: ${Colors.black};
  margin: 0;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
  
  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export {
  Card,
  Title,
  Image,
  ImageContainer,
  RightContainer,
  Price,
};
