import React, { useContext } from 'react';

import {
  Card,
  Title,
  Image,
  ImageContainer,
  RightContainer,
  Price,
} from './styles';

import CartProductAmount from '../CartProductAmount';
import { CartContext } from '../../state/CartContext';
import { useUserContext } from '../../state/UserContext';

function CartItem({
  productId,
  Divider,
  itemInfo,
}) {
  const { cartState } = useContext(CartContext);
  const [userData] = useUserContext();

  const productAmount = cartState[productId].amount;

  const currentPrice = itemInfo ? itemInfo.price * productAmount : 0;

  return (
    <>
      <Card>
        <ImageContainer>
          <Image src={itemInfo?.image} />
        </ImageContainer>
        <Title>
          {itemInfo?.name}
        </Title>
        <RightContainer>
          <CartProductAmount
            itemInfo={itemInfo}
          />
          {userData?.machine?.sampling !== true && (
          <Price>{'R$ '.concat((currentPrice || 0).toFixed(2).toString().replace('.', ','))}</Price>)}
        </RightContainer>
      </Card>
      <Divider />
    </>
  );
}

export default CartItem;
