import React, { useState, useEffect } from 'react';

import { areYouThere } from '../../texts.json';

import {
  ModalBase,
  Content,
  Overlay,
  Title,
  Button,
  ProgressContainer,
  ProgressWrapper,
  Progress,
} from './styles';

const WAIT_DURATION = 10;

function AreYouThereModal({ closeModal, onTimeUp }) {
  const [remainingTime, setRemainingTime] = useState(WAIT_DURATION);

  const format = (percent) => {
    const seconds = ((percent / 10).toFixed(0)).toString();

    const stringSeconds = seconds.length > 1 ? seconds : `0${seconds}`;

    return `00:${stringSeconds}`;
  };

  useEffect(() => {
    const timeout = setTimeout(onTimeUp, WAIT_DURATION * 1000);

    return () => { clearTimeout(timeout); };
  }, []);

  useEffect(() => {
    setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 0.01);
    }, 10);
  }, []);

  return (
    <ModalBase>
      <Overlay />
      <Content>
        <ProgressContainer>
          <ProgressWrapper>
            <Progress
              type="circle"
              percent={remainingTime * 10}
              format={format}
              strokeColor={{
                '-40%': '#00efd1',
                '169%': '#00acea',
              }}
              strokeWidth="5"
              width="479px"
            />
          </ProgressWrapper>
        </ProgressContainer>
        <Title>{areYouThere}</Title>
        <Button onClick={closeModal}>
          Sim, estou
        </Button>
      </Content>
    </ModalBase>
  );
}

export default AreYouThereModal;
