import styled from 'styled-components';
import Colors from '../../styles/Colors';

const ErrorList = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 3000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height .5s ease-in-out;
  width: 15rem;
`;

const ErrorCard = styled.button`
  width: 100%;
  background-color: ${({ isSuccess }) => (isSuccess ? '#99ff99' : '#F7E2E3')};
  border: 1px solid ${({ isSuccess }) => (isSuccess ? '#000' : 'red')};
  border-radius: .5rem;
  padding: ${({ active }) => (active ? '.5rem 1rem' : '0 1rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ isSuccess }) => (isSuccess ? '#000' : 'red')};
  margin: ${({ active }) => (active ? '.3rem 0' : '0')};
  max-height: 0;
  transition:
    opacity .25s ease-out,
    max-height .5s ease-out,
    margin .5s ease-out,
    padding-top .5s ease-out,
    padding-bottom .5s ease-out;
  max-height: ${({ active }) => (active ? '15rem' : '0rem')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  overflow: hidden;
  cursor: pointer;
  outline: none;
`;

const Text = styled.p`
  font-size: .7rem;
  text-align: left;
  margin: .3rem 0;
  width: 100%;
  color: ${Colors.secondaryBlack};

  span {
    background-color: ${({ isSuccess }) => (isSuccess ? 'transparent' : '#bdbdbd')};
    padding: .1rem .2rem;
    border-radius: .3rem;
    letter-spacing: 1px;
    font-size: .6rem;
  }
`;

const Title = styled.h3`
  font-size: .8rem;
  text-align: center;
  margin-bottom: 0;
  color:  ${({ isSuccess }) => (isSuccess ? '#000' : 'red')};
`;

const Icon = styled.img`
  margin: 0 .5rem;
  transform: rotate(${({ upside }) => (upside ? '180deg' : '0deg')});
  transition: transform .5s ease-out;
  fill: #000;
  color: #000;
`;

const DetailsContainer = styled.div`
  font-size: .7rem;
  margin: 0;
  width: 100%;
  overflow: hidden;
  max-height: ${({ active, height }) => (active ? `${height}px` : '0rem')};
  transition: max-height .5s ease-out;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: .5rem 0;
`;

export {
  ErrorList,
  ErrorCard,
  Text,
  Icon,
  DetailsContainer,
  Title,
  MessageContainer,
};
