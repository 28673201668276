import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: ${({ page, small }) => (page ? '100vh' : (small ? '25vh' : '100%'))};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  Container,
};
