import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  max-height: ${({ active }) => (active ? '400px' : '0px')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  width: 100%;
  padding: ${({ active }) => (active ? '10px' : '0px')} 10px;
  transition: max-height .5s ease-in-out, padding .5s ease-in-out;
  color: rgba(0, 0, 0, 0.65);

  ${({ active }) => !active && css`
    transition: opacity .5s ease-in-out;
  `}

  .hg-button {
    height: 60px;
    span {
      font-size: 25px;
    }
  }

  @media(max-width: 768px) {
    display: none;
  }
`;

export const NumberWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  max-height: ${({ active }) => (active ? '700px' : '0px')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  width: 100%;
  padding: ${({ active }) => (active ? '8rem' : '0px')} 8rem;
  transition: max-height .5s ease-in-out, padding .5s ease-in-out;
  color: rgba(0, 0, 0, 0.65);

  ${({ active }) => !active && css`
    transition: opacity .5s ease-in-out;
  `}

  .hg-button {
    height: 120px;
    span {
      font-size: 50px;
    }
  }

  @media(max-width: 768px) {
    display: none;
  }
`;