import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { Redirect } from 'react-router';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Input, Switch } from 'antd';

import { toast } from 'react-toastify';

import InputMask from 'react-input-mask';

import { LowerAdSection } from '../../styles/globalStyle';
import {
  CartSection,
  Content,
  Header,
  LogoVend,
  BrandIcon,
  CreditCardContainer,
  CreditCardSubContainer,
} from './styles';

import api, { axiosGet, axiosPost } from '../../services/api';

import Mastercard from './Assets/mastercard.svg';
import Elo from './Assets/elo.svg';
import Visa from './Assets/visa.svg';
import Amex from './Assets/amex.svg';
import JCB from './Assets/jcb.svg';
import Aura from './Assets/aura.svg'
import Hipercard from './Assets/hipercard.svg'
import Diners from './Assets/diners.svg';
import Discover from './Assets/discover.svg'
import Generic from './Assets/credit-card.svg'
import logotipovend from './Assets/logo-vend-market.svg';
import people04 from './Assets/people04.png';
import background from './Assets/bg-vend-market.jpg';
import UserContext from '../../state/UserContext';
import WarnModal from '../../components/WarnModal';
import AddressModal from './components/AdressModal';
import CreditCardModal from './components/CreditCardModal';


function CustomerCards() {
  const [redirect, setRedirect] = useState(null);
  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    name: '',
    expiration: '',
    cvv: '',
    holderDocument: '',
  });
  const [cep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [address, setAddress] = useState({});
  const [size, setSize] = useState('large');
  const [userInfo, setUserInfo, getUserInfo] = useContext(UserContext);
  const [modalAddressShow, setModalAddressShow] = useState(false);
  const [modalCardShow, setModalCardShow] = useState(false);
  const [modalUpdateShow, setModalUpdateShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const goTo = (page) => {
    setRedirect({ pathname: `/${page}` });
  };

  const addressSubmit = async () => {
    if(cep.length === 8 && numero.length > 0 && address?.localidade){
      const response = await api.patch(`/customers/${userInfo?.customer._id}`, {
        address: {
          public_place: address.logradouro,
          neighborhood: address.bairro,
          complement: complemento ? complemento : '',
          number: numero,
          cep: cep,
          city: address.localidade,
          state: address.uf,
      }
      });
      setUserInfo({
        ...userInfo,
        customer: {
          ...userInfo.customer,
          address: response.data.data.address,
        }
      });
      setModalCardShow(true);
    } else if (cep.length < 8){
      throw new Error("CEP inválido");
    } else if (numero.length === 0){
      throw new Error("Número é obrigatório");
    } else {
      throw new Error("Ocorreu um erro, tente novamente");
    }
  }

  useEffect(() =>{
   const getCep = async () => {
      const response = await axiosGet(`/get-cep/?cep=${cep}`);
      setAddress(response.data);
   } 
   if (cep.length === 8) {
     getCep();
   }
  }, [cep]);

  function isCardValid(card) {
    if (card.cardNumber === '' || card.name === '' || card.expiration === '' || card.cvv === '' || card.holderDocument === '') {
      return false;
    }
    if (card.cardNumber.length !== 19) {
      return false;
    }
    if (card.expiration.length !== 5) {
      return false;
    }
    if (!(card.cvv.length === 4 || card.cvv.length === 3 )) {
      return false;
    }
    if (card.holderDocument.length !== 14){
      return false;
    }
    return true;
  }
function cardBrand(){
  switch(userInfo?.customer.cards[0]?.brand ){
    case "Mastercard" :
      return Mastercard;
    case "Elo":
      return Elo;
    case "Visa":
      return Visa;
    case "Amex":
      return Amex;
    case "JCB":
      return JCB;
    case "Aura":
      return Aura;
    case "Hipercard":
      return Hipercard;
    case "Diners":
      return Diners;
    case "Discover":
      return Discover;
    default:
      return Generic;
  }
}
  const handleSubmit = async () => {
    try {

      if (!isCardValid(cardInfo)) {
        toast.error('Todos os campos devem ser preenchidos corretamente.');
        return false;
      }
      const cardExpMonth = cardInfo.expiration.split('/')[0];
      const cardExpYear = cardInfo.expiration.split('/')[1];
      const holderDocument = cardInfo.holderDocument.replaceAll(".", '').replace('-', '');
      await api.post(`/pagarme/${userInfo?.customer._id}`, {
        cardData: {
          number: cardInfo.cardNumber.replaceAll(" ",""),
          holder_name: cardInfo.name,
          exp_month: cardExpMonth,
          exp_year: cardExpYear,
          cvv: cardInfo.cvv,
          holder_document: holderDocument,
        }
      });
      getUserInfo();
      toast.success('Dados atualizados com sucesso.');
    } catch (err) {
      if (err.response?.status === 400) { toast.error('Já existe um cadastro com este email e telefone'); } else { toast.error('Ocorreu um erro, consulte a equipe de suporte'); }
    }

  };

  const handleDelete = async () => {
    await api.delete(`/pagarme/?customerId=${userInfo?.customer._id}&cardId=${userInfo?.customer.cards[0]._id}`);
    getUserInfo();
  };

  const handleUpdate = async() => {
    await handleDelete();
    await handleSubmit();
  }

  if (redirect) return <Redirect to={redirect} />;
  return (
    <>
      <LowerAdSection>
        <CartSection className="bg-inicial" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
          <Header style={{
            backgroundImage: `url(${people04})`,
            backgroundSize: '30%',
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
          }}
          >
            <LogoVend src={logotipovend} />
          </Header>
           
          <Content>
            <h2 style={{ fontWeight: 'bolder' }}>Meu Cartão</h2>
            { userInfo?.customer.cards[0] ? (
            <>
              <h4>No momento, aceitamos apenas cartão de crédito ou pix.</h4>
              <br />
              <CreditCardContainer>
                <BrandIcon src={cardBrand()} />
                <CreditCardSubContainer>
                  <h3>{userInfo?.customer.cards[0]?.brand}</h3>
                  <h3> ●●●● {userInfo?.customer.cards[0]?.last_four_digits}</h3>
                </CreditCardSubContainer>
              </CreditCardContainer>
              <br/>
              <Button type="primary" size={size} block className="azul" onClick={() => setModalUpdateShow(true)}>
                Atualizar Cartão
              </Button>
              <Button type="primary" size={size} className='vermelho' block onClick={()=>setModalDeleteShow(true)}>
                Excluir Cartão</Button>
              <Button type="default" size={size} block className="" onClick={() => goTo('customer_menu')}>
                Voltar
              </Button>
              <br />
            </>
            ) : 
            (
          <>
            <h4>Você não possui nenhum cartão cadastrado</h4>
            <Button type='primary' size={size} block className="azul" onClick={ userInfo?.customer.address ? 
            () => setModalCardShow(true) : () => setModalAddressShow(true)}>
              Cadastrar Cartão</Button>
            <Button type="default" size={size} block className="" onClick={() => goTo('customer_menu')}>
              Voltar
            </Button>
          </>
          )
          }
        </Content>
        </CartSection>
      </LowerAdSection>
      <AddressModal
      handleSubmit={addressSubmit}
      active={modalAddressShow}
      setActive={setModalAddressShow}
      cepState={{state: cep, setState: setCep}}
      addressState={{state: address, setState: setAddress}}
      numeroState={{state: numero, setState: setNumero}}
      complementoState={{state: complemento, setState: setComplemento}}
      >
      </AddressModal>
      <CreditCardModal
        handleSubmit = {handleSubmit}
        active = {modalCardShow}
        setActive = {setModalCardShow}
        cardInfoState = {{state: cardInfo, setState: setCardInfo}}
        title = "Cadastrar Cartão"
      >
      </CreditCardModal>
      <CreditCardModal
        handleSubmit={handleUpdate}
        active={modalUpdateShow}
        setActive={setModalUpdateShow}
        cardInfoState = {{state: cardInfo, setState: setCardInfo}}
        title = "Atualizar Cartão"
      >
      </CreditCardModal>
      <WarnModal 
      title= 'Excluir Cartão'
      text = 'Tem certeza que deseja excluir seu Cartão?'
      accept = {handleDelete}
      deny = {() => setModalDeleteShow(false)}
      active = {modalDeleteShow}
      setActive={setModalDeleteShow}
      >
      </WarnModal>
     
      <br />
    </>
  );
}

export default CustomerCards;
