import { useState, useLayoutEffect } from 'react';

export function useWindowSize() {
  const [data, setData] = useState(window.devicePixelRatio);

  useLayoutEffect(() => {
    function updateSize() {
      setData(window.devicePixelRatio);
    }

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return data;
}
