import styled from 'styled-components';
import Colors from '../../styles/Colors';

import {
  Header as BaseHeader,
} from '../../components/Header/styles';

const CartSection = styled.section`
  position: relative;
  width: 100%;
  overflow-y: auto;
  justify-content:center;
  text-align:center;
  height:100vh;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  flex-grow: 1;
  padding:2em;

  .tem-conta{
    margin: 1em 0 .5em 0;
    color:#fff;
  }
  .termo{
    display:flex;
    align-items:left;
    color:#fff;
    width:100%;
    margin:.7em 0;
  }

  .termo button{
    margin-right:.5em;
  }

  @media (max-width: 1024px) {
    justify-content:center;
    text-align:center;
     
    h2{
      text-transform:uppercase;
    }
    h3{
      margin:0;
    }
    h2, h3, h4{
      color:#fff;
    }
    
    button.ant-btn-block, input{
      margin:.4em 0;
      padding:.8em .5em;
      text-transform: uppercase;
      border:none;
      border-radius:5px;
      height:auto;
      font-size: .9em;
      font-weight: 500;
    }
    button.azul{
      background:#005bb8;
    }
    button.clique{
      background:#1578c6;
    }
  }

  @media (max-width: 320px) {
    padding-top: 4rem;
  }
  
`;

const LogoVend = styled.img`
height: 6rem;
width:auto;
`;

const People02 = styled.img`
height: 8rem;
`;

const Header = styled(BaseHeader)`

  align-items:center;
  justify-content:center;
  display:flex;
  padding-top:8em;

  @media (max-width: 1024px) {
    padding:10em 0 0 0;
    margin: 2em 0 0 0;
  }

  @media (max-width: 320px) {
    margin-top: 20px;
  }
`;

export {
  Header,
  CartSection,
  Content,
  LogoVend,
  People02,
};
