import io from 'socket.io-client';
import { url } from './api';

const socket = io.connect(url);

socket.on('connect', (client) => {
  console.log(`Socket.io connected`, client);
});

export default socket;
