import React, { useEffect, useState, useContext } from 'react';
import { Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Redirect, useHistory } from 'react-router';

import { LazyLoader } from '../components';
import { checkToken } from '../services/api';
import { Auth } from '../pages';
import TimerContext from '../state/TimerContext';

import { PageContent } from '../styles/globalStyle';
import socket from '../services/socket';

function PrivateRoute({
  path,
  exact,
  component: Comp,
  location,
  admin,
  ...props
}) {
  const [logged, setLogged] = useState(null);
  const history = useHistory();
  const [cookies, , removeCookies] = useCookies(['token', 'touchless']);

  const { resetTimer, timer } = useContext(TimerContext);

  useEffect(() => {
    const checkLogged = async () => {
      try {
        const isLogged = await checkToken();
        setLogged(isLogged);
      } catch (err) {
        setLogged(err);
      }
    };

    checkLogged();
  }, []);

  useEffect(() => {
    if (cookies.touchless === 'true') {
      const logout = () => {
        removeCookies('token', { path: '/' });
        removeCookies('user_id', { path: '/' });
        localStorage.removeItem('ads');
        localStorage.removeItem('userData');
        setLogged(false);
        return history.push('/expired');
      };

      // comentado para reavaliacao - muitas sessoes se desconectando
      //socket.on('logoutTouchless', logout);

      return () => {
        socket.off('logoutTouchless', logout);
      };
    }

    return () => { };
  }, [cookies.touchless]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const sessionToken = params.get('sessionToken');

    if (sessionToken) setLogged(false);

    window.addEventListener('wheel', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('dragstart', resetTimer);
    window.addEventListener('keydown', resetTimer);
  }, []);

  if (location.pathname === '/logout') {
    if (cookies.touchless === 'true') {
      // comentado para reavaliacao - muitas sessoes se desconectando
      //socket.emit('logoutTouchless', JSON.parse(localStorage.getItem('userData'))?.machineId || '');
    }
    removeCookies('token', { path: '/' });
    removeCookies('user_id', { path: '/' });
    removeCookies('customer_id', { path: '/' });
    localStorage.removeItem('ads');
    localStorage.removeItem('userData');
    if (logged) setLogged(false);
    return <Redirect to="/" />;
  }

  if (
    logged === null
  ) return <LazyLoader page />;

  if (logged === false) return <Auth setLogged={setLogged} />;

  if (timer.showSleep) {
    return (
      <Route {...props}>
        <Comp resetTimer={resetTimer} />
      </Route>
    );
  }

  return (
    <Route {...props}>
      <PageContent>
        <Comp />
      </PageContent>
    </Route>
  );
}

export default PrivateRoute;
