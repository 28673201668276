import React, {
  useState,
  useContext,
  useEffect,
} from 'react';
import { Redirect, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { cardTexts } from '../../texts.json';
import TimerContext, { useDifferentTimeoutLimit } from '../../state/TimerContext';

import {
  Content,
  Text,
  Icon,
  CancelButton,
  CloseIcon,
  FooterCancelButton,
} from './styles';

import closeIcon from './Assets/close.png';
import cardIcon from './Assets/card.svg';
import { CartContext } from '../../state/CartContext';
import CouponContext from '../../state/CouponContext';
import UserContext from '../../state/UserContext';
import { axiosPost } from '../../services/api';

import ErrorModal from './ErrorModal';
import errorMap from './errorMap';
import promiseTimeout from '../../utils/promiseTimeout';
import promisifiedSocket from '../../utils/promisifiedSocket';
import LoadingScreen from './LoadingScreen';
import PixScreen from './PixScreen';

function PaymentInstruction() {
  const location = useLocation();
  const [cookies] = useCookies(['touchless']);
  const history = useHistory();
  const { cartState, cartDispatch } = useContext(CartContext);
  const { resetTimer } = useContext(TimerContext);
  const [coupon] = useContext(CouponContext);
  const [user] = useContext(UserContext);
  const [redirect, setRedirect] = useState(null);
  const [saleCreated, setSaleCreated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [qrCodeLink, setQrCodeLink] = useState('');
  const [transactionIdServer, setTransactionIdServer] = useState('');

  const { paymentType, buyerMobile } = location?.state || {};

  useDifferentTimeoutLimit(180);

  const cleanCart = () => cartDispatch({ type: 'CLEAN_CART' });

  const cancelAction = () => {
    console.log({ transactionIdServer });
    axiosPost('/sales/healthcheck', {
      transactionId: transactionIdServer,
      paymentType,
      machineId: user.machineId,
    }, () => {
      cleanCart();
      setRedirect('/carrinho');
    }, () => {
      //
    });
  };

  const selectPaymentTypeAgain = () => setRedirect({ pathname: '/checkout', state: location.state });
  const resetAllAndGoToProducts = () => {
    cleanCart();
    resetTimer();
    history.push('/');
  };
  const success = () => {
    cleanCart();
    resetTimer();
    history.push('/success');
  };

  const handleTimeoutError = () => {
    console.error('VMACH: Timeout error');
    setError('card');
    setLoading(false);
  };

  const isPix = paymentType === 'pix';

  useEffect(() => {
    const makeSale = async () => {
      try {
        axiosPost('/sales', {
          machineId: user.machineId,
          customerId: user?.customer?._id || null,
          items: Object.values(cartState).map(({ itemId, amount, price }) => ({
            itemId,
            priceAtTheMoment: price,
            quantity: amount,
          })),
          paymentType,
          buyerMobile,
          ...(coupon ? { coupon: coupon.code } : {}),
        }, () => {
          setSaleCreated(true);
          setError(false);
        }, () => {
          handleTimeoutError();
        });

        if (isPix) {
          const qrcodePromise = promisifiedSocket('qrcode', (result) => result.machineId === user.machineId);
          const {
            qrcode,
            qrcodeLink,
            transactionId,
          } = await promiseTimeout(qrcodePromise, 5000, handleTimeoutError);

          resetTimer();
          setQrCodeImage(qrcode);
          setQrCodeLink(qrcodeLink);
          setTransactionIdServer(transactionId);
        }

        const paymentConfirmedPromise = promisifiedSocket('paymentConfirmed', (resultMachineId) => resultMachineId === user.machineId);
        const successMachineId = await promiseTimeout(
          paymentConfirmedPromise,
          120000,
          handleTimeoutError,
        );

        setLoading(true);

        if (successMachineId) {
          const itemsReleased = await promisifiedSocket('itemsReleased', (resultMachineId) => resultMachineId === user.machineId);

          if (itemsReleased) {
            success();
          }
        }
      } catch (err) {
        const errorCode = err?.response?.data?.message;

        if (errorCode) {
          if (Object.keys(errorMap).includes(errorCode)) {
            setError(errorCode);
          } else {
            setError('card');
          }
        } else {
          setError('card');
        }
      } finally {
        setLoading(false);
      }
    };

    if (Object.keys(cartState).length > 0 && Object.keys(user).length > 0) {
      makeSale();
    } else {
      resetAllAndGoToProducts();
    }
  }, [cartState, user]);

  if (!paymentType) return <Redirect to="/" />;
  if (redirect) return <Redirect to={redirect} />;
  if (loading) return <LoadingScreen text={['Aguardando liberação do produto']} />;

  if (!saleCreated && error && !loading) {
    return (
      <ErrorModal
        visible={error}
        error={errorMap[error]}
        onTryAgain={selectPaymentTypeAgain}
        onCancel={resetAllAndGoToProducts}
      />
    );
  }

  const isTouchless = cookies.touchless === 'true';

  return (
    <>
      <CancelButton onClick={cancelAction}>
        <CloseIcon src={closeIcon} alt="" />
      </CancelButton>

      <Content>
        {!isPix && (
          <>
            <Text>
              1)
              {' '}
              {cardTexts[0]}
            </Text>
            <Icon src={cardIcon} />
            <Text>
              2)
              {' '}
              {cardTexts[1]}
            </Text>
          </>
        )}

        {isPix && (
          <PixScreen
            isTouchless={isTouchless}
            qrCodeImage={qrCodeImage}
            qrCodeLink={qrCodeLink}
          />
        )}
        <FooterCancelButton onClick={cancelAction}>
          Cancelar Compra
        </FooterCancelButton>
      </Content>
    </>
  );
}

export default PaymentInstruction;
