import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Content = styled.img`
  display: flex;

  width: 100%;
  min-height: 25vh;
  max-height: 25vh;
  overflow: hidden;
  z-index: 5;

  background-color: ${Colors.background};
  object-fit: cover;
`;

const ContentVideo = styled.video`
  display: flex;

  width: 100%;
  min-height: 25vh;
  max-height: 25vh;
  overflow: hidden;
  z-index: 5;

  background-color: ${Colors.background};
  object-fit: cover;
`;

export {
  Content,
  ContentVideo,
};
