import axios from 'axios';

const sintegra = axios.create({
  baseURL: 'https://www.sintegraws.com.br/api/v1/execute-api.php',
});

const token = '9A678AA9-C716-4BE9-AC31-5645AD9C6343'

const getCpf = async (
  dados = {},
  setState = () => { },
  process = (data) => data,
  sucess = () => { },
  error = () => { },
) => {
  try {

    const relativeUrl = `?token=${token}&cpf=${dados.cpf}&data-nascimento=${dados.data_nascimento}&plugin=CPF`
    const res = await sintegra.get(relativeUrl);

    if (setState) {
      setState(await process(res.data.data));
    } else {
      process(res.data.data);
    }
    sucess(res);
    return {
      code: '200',
      ...res,
    };
  } catch (err) {
    error(err);
    
    return { code: '404', response: err.response };
  }
};

export default sintegra;

export {
  getCpf,
};
